import React from 'react';
import { I18n } from 'react-redux-i18n';
import Home from '../pages/home/form';
import OutboundOrdersDashboard from '../pages/dashboards/outboundOrderDashboard';
import InboundOrderDashboard from '../pages/dashboards/inboundOrderDashboard';
import InventoryDashboardPerBranch from '../pages/inventory/dashboard/inventoryGeneralDashboard';
import AddressOccupationDashboard from '../pages/stock/dashboard/addressOccupationDashboard';
import PageNotFound from '../pages/PageNotFound';
import Login from '../pages/login/login';
import UserList from '../pages/admin/users/user/list';
import UserForm from '../pages/admin/users/user/form';
import DepartmentList from '../pages/admin/users/departments/list';
import DepartmentForm from '../pages/admin/users/departments/form';
import UserFunctionList from '../pages/admin/users/userFunctions/list';
import UserFunctionForm from '../pages/admin/users/userFunctions/form';
import WorkShiftList from '../pages/admin/users/workShifts/list';
import WorkShiftForm from '../pages/admin/users/workShifts/form';
import IndirectTaskList from '../pages/admin/users/indirectTasks/list';
import IndirectTaskForm from '../pages/admin/users/indirectTasks/form';
import CompanyList from '../pages/admin/general/company/list';
import CompanyForm from '../pages/admin/general/company/form';
import BranchList from '../pages/admin/general/branch/list';
import BranchForm from '../pages/admin/general/branch/form';
import BranchSettingsForm from '../pages/admin/general/branch/settings/form';
import WarehouseList from '../pages/admin/general/warehouse/list';
import WarehouseForm from '../pages/admin/general/warehouse/form';
import DepositList from '../pages/admin/general/deposit/list';
import DepositForm from '../pages/admin/general/deposit/form';
import DockList from '../pages/admin/general/dock/list';
import DockForm from '../pages/admin/general/dock/form';
import DockLinkCarrierForm from '../pages/admin/general/dock/linkCarrier';
import RangeList from '../pages/admin/general/range/list';
import RangeForm from '../pages/admin/general/range/form';
import StorageAddressList from '../pages/admin/general/address/list';
import CurvesList from '../pages/admin/general/curves/list';
import CurveForm from '../pages/admin/general/curves/Form';
import StorageAddressForm from '../pages/admin/general/address/form';
import StorageAddressSizeList from '../pages/admin/general/addressSizes/list';
import StorageAddressSizeForm from '../pages/admin/general/addressSizes/form';
import StorageAddressFormMain from '../pages/admin/general/address/formMain';
import StorageAddressTypeList from '../pages/admin/general/addressTypes/list';
import StorageAddressTypeForm from '../pages/admin/general/addressTypes/form';
import PrinterList from '../pages/admin/general/printer/list';
import PrinterForm from '../pages/admin/general/printer/form';
import CollectorList from '../pages/admin/general/collector/list';
import CollectorForm from '../pages/admin/general/collector/form';
import CustomerList from '../pages/admin/general/customer/list';
import CustomerForm from '../pages/admin/general/customer/form';
import CustomerBranchList from '../pages/admin/general/customerBranch/list';
import CustomerBranchForm from '../pages/admin/general/customerBranch/form';
import TransportEquipmentForm from '../pages/admin/general/transportEquipment/form';
import TransportEquipmentList from '../pages/admin/general/transportEquipment/list';
import SupplierList from '../pages/admin/general/supplier/list';
import SupplierForm from '../pages/admin/general/supplier/form';
import CarrierList from '../pages/admin/general/carrier/list';
import CarrierForm from '../pages/admin/general/carrier/form';
import CarrierLinkDocksForm from '../pages/admin/general/carrier/linkDocks';
import PriorityList from '../pages/admin/general/priority/list';
import PriorityForm from '../pages/admin/general/priority/form';
import ScheduleList from '../pages/admin/general/schedule/list';
import ScheduleForm from '../pages/admin/general/schedule/form';
import ProductList from '../pages/admin/products/product/list';
import ProductForm from '../pages/admin/products/product/form';
import MaterialFamilyList from '../pages/admin/products/materialFamily/list';
import MaterialFamilyForm from '../pages/admin/products/materialFamily/form';
import CustomerProductList from '../pages/admin/products/customerProducts/list';
import CustomerProductForm from '../pages/admin/products/customerProducts/form';
import ProductBranchList from '../pages/admin/products/productBranches/list';
import ProductBranchForm from '../pages/admin/products/productBranches/form';
import UnitForm from '../pages/admin/products/unit/form';
import UnitList from '../pages/admin/products/unit/list';
import LotLabelForm from '../pages/movement/label/lot/form';
import LabelEanForm from '../pages/movement/label/ean/form';
import LabelAddressForm from '../pages/movement/label/address/form';
import LabelVolumeForm from '../pages/movement/label/volume/form';
import LabelKardexCableCutForm from '../pages/movement/label/kardexCableCut/form';
import EanForm from '../pages/admin/products/ean/form';
import EanList from '../pages/admin/products/ean/list';
import PackageList from '../pages/admin/products/package/list';
import PackageForm from '../pages/admin/products/package/form';
import InventoryErpList from '../pages/inventory/erp/list';
import DevelopI18nList from '../pages/develop/i18n/list';
import DevelopI18nForm from '../pages/develop/i18n/form';
import DevelopAgentList from '../pages/develop/agent/list';
import DevelopAgentForm from '../pages/develop/agent/form';
import DevelopAccessProfileList from '../pages/develop/accessProfile/list';
import DevelopAccessProfileForm from '../pages/develop/accessProfile/form';
import DevelopAccessProfileManager from '../pages/develop/accessProfile/manager';
import EntranceVolumePlate from '../pages/entrance/volumePlate';
import PlateCheckForm from '../pages/entrance/plateCheck';
import InboundCheckForm from '../pages/inbound/check/checkForm';
import InboundStorageForm from '../pages/inbound/storage/storageForm';
import ReturnPickingOrder from '../pages/outbound/returnPickingOrder/index';
import InboundOrderList from '../pages/inbound/orders/list';
import InboundOrderForm from '../pages/inbound/orders/form';
import InboundOrderAdd from '../pages/inbound/orders/add';
import InboundOrderPanel from '../pages/inbound/panel/panel';
import InboundProductMonitor from '../pages/inbound/inboundProductMonitor/list';
import StockBalanceList from '../pages/stock/balance/list';
import StockTransferForm from '../pages/stock/transfer/form';
import DetailedStockBalanceList from '../pages/stock/DetailedBalance/list';
import AverageCost from '../pages/stock/averageCost/list';
import AverageTimeAnalysisDashboard from '../pages/outbound/dashboard/averageTimeAnalysisDashboard';
import OutboundOrderList from '../pages/outbound/orders/list';
import OutboundOrderForm from '../pages/outbound/orders/form';
import OutboundWaveForm from '../pages/outbound/waveCreate/form';
import OutboundOrderPicking from '../pages/outbound/picking/form';
import OutboundOrderPickingWave from '../pages/outbound/pickingWave/form';
import StockMovementList from '../pages/stock/movement/list';
import ResupplyMonitorList from '../pages/resupply/resupplyMonitor/list';
import OutboundOrderCheck from '../pages/outbound/check/form';
import VolumeCheckForm from '../pages/outbound/volumeCheck/form';
import OutboundMonitor from '../pages/outbound/monitor/index';
import OutboundPendingAllocationMonitor from '../pages/outbound/pendingAllocationMonitor/index';
import OutboundOrderWaveMonitor from '../pages/outbound/waveMonitor/list';
import OutboundConsolidation from '../pages/outbound/consolidation/list';
import OutboundDocks from '../pages/outbound/docks/list';
import DockControlMonitor from '../pages/outbound/DockControlMonitor/list';
import DockVolumeRemovalMonitor from '../pages/outbound/DockVolumeRemovalMonitor/list';
import RestrictionMonitorList from '../pages/outbound/restrictionMonitor/list';
import AllocatedProductList from '../pages/outbound/allocatedProduct/list';
import ReportAdminGeneralCompany from '../pages/reports/admin_general_company';
import ReportAdminGeneralBranch from '../pages/reports/admin_general_branch';
import ReportAdminGeneralWarwhouse from '../pages/reports/admin_general_warehouse';
import ReportAdminGeneralDeposit from '../pages/reports/admin_general_deposit';
import ReportAdminGeneralDock from '../pages/reports/admin_general_dock';
import ReportAdminGeneralCustomer from '../pages/reports/admin_general_customer';
import ReportAdminProductProducts from '../pages/reports/admin_product_products';
import ReportAdminProductEan from '../pages/reports/admin_product_ean';
import ReportAdminProductPacking from '../pages/reports/admin_product_packing';
import ReportAdminSupplier from '../pages/reports/admin_general_suppliers';
import ReportAdminGeneralStorageAddress from '../pages/reports/admin_general_storage_address';
import ReportCurves from '../pages/reports/admin_general_curves';
import ReportProductBranch from '../pages/reports/admin_product_branch';
import ReportAdminProductsCustomer from '../pages/reports/admin_products_productsCustomer';
import ReportAdminProductUnitsMeasure from '../pages/reports/admin_product_unitsMeasure';
import ReportAdminUserUsers from '../pages/reports/admin_user_users';
import ReportAdminGeneralPrinter from '../pages/reports/admin_general_printer';
import ReportAdminGeneralCollector from '../pages/reports/admin_general_collector';
import ReportTransportEquipment from '../pages/reports/register_equips_transport_equipment';
import ReportAdminGeneralCarrier from '../pages/reports/admin_general_carrier';
import ReportStockStockBalance from '../pages/reports/stock_stockBalance';
import ReportStockMovementAdress from '../pages/reports/stock_movementAdress';
import ReportMovementInboundOrderList from '../pages/reports/movement_inbound_order_list';
import ReportMovementInboundOrdersItemsList from '../pages/reports/movement_inbound_orders_items_list';
import ReportMovementExpeditionOutboundList from '../pages/reports/movement_expedition_outbound_list';
import ReportMovementExpeditionOutboundLot from '../pages/reports/movement_expedition_outbound_lot';
import ReportMovementExpeditionOutboundOrdersItemsList from
  '../pages/reports/movement_expedition_outbound_orders_items_list';
import ReportMovementExpeditionOutboundOrdersItemsRangeList from
  '../pages/reports/movement_expedition_outbound_orders_items_range_list';
import ReportMovementInboundOrderPreEntry from '../pages/reports/movement_inbound_orderPreEntry';
import ReportMovementExpeditionVolumeDocumentList from '../pages/reports/movement_expedition_volume_document_list';
import ReportMovementExpeditionItemsPendingAllocation from
  '../pages/reports/movement_expedition_items_pending_allocation';
import ReportAdminGeneralRange from '../pages/reports/admin_general_range';
import ReportAdminGeneralRangesByUser from '../pages/reports/admin_general_rangesByUser';
import ReportAdminGeneralRanges from '../pages/reports/admin_general_ranges';
import ReportAdminGeneralPriorities from '../pages/reports/admin_general_priorities';
import ReportRegistrationGeneralTypeAddress from '../pages/reports/registration_general_typeAddress';
import ReportAdminUsersDepartments from '../pages/reports/admin_users_departments';
import ReportAdminUsersFunctions from '../pages/reports/admin_users_usersFunctions';
import ReportAdminUsersWorkTurns from '../pages/reports/admin_users_workTurns';
import ReportAdminUsersIndirectTasks from '../pages/reports/admin_users_indirectTasks';
import ReportMovementExpeditionCheckProductsRange from '../pages/reports/mov_expedition_checkProdRange';
import ReportStockEmptyAddresses from '../pages/reports/stock_emptyAddresses';
import ReportStockAllocationAddress from '../pages/reports/stock_allocationAddress';
import ReportMovementExpeditionCheckQtyProductsPerCheckerOutbound from
  '../pages/reports/movement_expedition_checkQtyProductsPerCheckerOutbound';
import ReportMovementExpeditionCheckQtyProductsPerCheckerInbound from
  '../pages/reports/movement_expedition_checkQtyProductsPerCheckerInbound';
import ReportMovementExpeditionCheckQtyProductsSeparatedPerDateAndUser from
  '../pages/reports/movement_expedition_checkQtyProductsSeparatedPerDateAndUser';
import ReportProductCurveDivergences from '../pages/reports/KPIS_product_curve_divergences';
import ReportQtyProductsStoredPerUser from '../pages/reports/KPIS_qty_products_stored_per_user';
import ReportSalesOrderStatus from '../pages/reports/KPIS_sales_order_status';
import ReportRetail from '../pages/reports/KPIS_retail';
import ReportProductsSeparationDate from '../pages/reports/KPIS_products_separation_date';
import ReportInventoryCount from '../pages/reports/inventory_inventory_count';
import MassBlockList from '../pages/admin/general/address/massBlock';
import BaptismForm from '../pages/admin/general/baptism/form';
import BaptismLabelConsultInbound from '../pages/admin/general/baptism/consult/inbound/form';
import BaptismLabelConsultOutbound from '../pages/admin/general/baptism/consult/outbound/form';
import PickingReturn from '../pages/outbound/pickingReturn/form';
import CableCutPlan from '../pages/outbound/cableCutPlan/form';
import CableCutMonitList from '../pages/outbound/cableCutMonit/list';
import ExpeditionCheckList from '../pages/outbound/check/consult/list';
import WaveTransferForm from '../pages/stock/transfer/waveTransfer/form';
import WaveTransferMonitorList from '../pages/stock/transfer/waveTransferMonitor/list';
import WaveTransferMonitorForm from '../pages/stock/transfer/waveTransferMonitor/form';
import WaybillMonitor from '../pages/outbound/waybillMonitor/list';
import ApprovalLevelList from '../pages/inventory/approvalLevel/list';
import ApprovalLevelForm from '../pages/inventory/approvalLevel/form';
import GenerateInventoryList from '../pages/inventory/generate/list';
import InventoryMaintenanceList from '../pages/inventory/maintenance/list';
import InventoryMonitorList from '../pages/inventory/inventoryMonitor/list';
import CountUserList from '../pages/inventory/countUser/list';
import UpdateInventorySheet from '../pages/inventory/updateInventorySheet/list';
import JobsList from '../pages/admin/general/jobs/list';
import JobsForm from '../pages/admin/general/jobs/form';
import IntegrationMonitorList from '../pages/integration/integrationMonitor/list';
import PhysicalInventory from '../pages/inventory/physicalInventory/physicalInventory';
import LabelLotWithStockBalance from '../pages/movement/label/lotWithStockBalance/form';
import StockBalanceComparison from '../pages/stock/stockBalanceComparison/stockBalanceComparison';
import ReportStockExpiredProducts from '../pages/reports/stock_expired_products';
import ReceiptOverview from '../pages/dashboards/ReceiptOverview';
import ExpeditionOverview from '../pages/dashboards/ExpeditionOverview';
import AverageTimes from '../pages/inbound/dashboard/AverageTimes';
import Notification from '../pages/notification/notification-inbox';
import NotificationDetail from '../pages/notification/notification-detail';
import CapacityList from '../pages/admin/general/capacity/list';
import CapacityForm from '../pages/admin/general/capacity/form';
import ReportResupplyPicking from '../pages/reports/resupply_picking';
import ResupplyRulesList from '../pages/stock/resupplyRules/list';
import ResupplyRulesForm from '../pages/stock/resupplyRules/form';
import PickingAreaList from '../pages/admin/general/pickingArea/list';
import PickingAreaForm from '../pages/admin/general/pickingArea/form';
import PickingAreaLinkAddress from '../pages/admin/general/pickingArea/linkAddress';
import GenerateResupplyList from '../pages/stock/resupply/generate/list';
import ProductionOrderList from '../pages/movement/industry/productionOrder/list';
import LabelSerialList from '../pages/movement/industry/serialLabel/list';
import GenerateSerialLabel from '../pages/movement/industry/serialLabel/generateSerialLabel';
import PickingRestrictionMonitorList from '../pages/outbound/separationRestrictionMonitor/list';
import TypeOrdersList from '../pages/admin/general/typeOrders/list';
import TypeOrdersForm from '../pages/admin/general/typeOrders/form';
import GenerateManualRequest from '../pages/movement/industry/manualRequest/generateManualRequest';
import ResupplyDepositsList from '../pages/admin/general/resupplyDeposits/list';
import ResupplyDepositsForm from '../pages/admin/general/resupplyDeposits/form';
import OutboundMonitorIndustry from '../pages/outbound/ordersIndustryMonitor/list';
import PrintSerialGroup from '../pages/movement/industry/serialGroup/printSerialGroup';
import ChangeProductAllocation from '../pages/outbound/changeProductAllocation/list';
import InventoryMonitorSerialLogList from '../pages/inventory/InventoryMonitorSerialLog/list';
import ReportInventoryMonitorSheetSerialLog from '../pages/reports/inventory_monitor_sheet_serial_log';
import ConfirmDeliveryList from '../pages/outbound/confirmDelivery/list';
import IndustrialScaleList from '../pages/admin/general/industrialScale/list';
import IndustrialScaleForm from '../pages/admin/general/industrialScale/form';

import ROUTES from './routes';
import Wizard from '../pages/wizard/wizard';

const routes = [
  {
    path: ROUTES.LOGIN,
    title: I18n.t('BEE4' /* Entrar */),
    component: (props) => <Login {...props} />,
    privated: false,
  }, {
    path: ROUTES.HOME,
    title: I18n.t('BEE12' /* Início */),
    component: (props) => <Home {...props} />,
    // component: (props) => <OutboundPanel {...props} />,
  }, {
    path: ROUTES.USER_COPY,
    title: I18n.t('BEE1' /* Usuário */),
    component: (props) => <UserForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.USER_DETAIL,
    title: I18n.t('BEE1' /* Usuário */),
    component: (props) => <UserForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.USER_EDIT,
    title: I18n.t('BEE1' /* Usuário */),
    component: (props) => <UserForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.USER_CREATE,
    title: I18n.t('BEE1' /* Usuário */),
    component: (props) => <UserForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.USER_LIST,
    title: I18n.t('BEE1' /* Usuário */),
    component: (props) => <UserList {...props} />,
  }, {
    path: ROUTES.DEPARTMENT_COPY,
    title: I18n.t('BEE622' /* Departamento */),
    component: (props) => <DepartmentForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEPARTMENT_DETAIL,
    title: I18n.t('BEE622' /* Departamento */),
    component: (props) => <DepartmentForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEPARTMENT_EDIT,
    title: I18n.t('BEE622' /* Departamento */),
    component: (props) => <DepartmentForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEPARTMENT_CREATE,
    title: I18n.t('BEE622' /* Departamento */),
    component: (props) => <DepartmentForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEPARTMENT_LIST,
    title: I18n.t('BEE731' /* Departamentos */),
    component: (props) => <DepartmentList {...props} />,
  }, {
    path: ROUTES.USER_FUNCTION_COPY,
    title: I18n.t('BEE918' /* Função do Usuário */),
    component: (props) => <UserFunctionForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.USER_FUNCTION_DETAIL,
    title: I18n.t('BEE918' /* Função do Usuário */),
    component: (props) => <UserFunctionForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.USER_FUNCTION_EDIT,
    title: I18n.t('BEE918' /* Função do Usuário */),
    component: (props) => <UserFunctionForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.USER_FUNCTION_CREATE,
    title: I18n.t('BEE918' /* Função do Usuário */),
    component: (props) => <UserFunctionForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.USER_FUNCTION_LIST,
    title: I18n.t('BEE881' /* Funções do Usuário */),
    component: (props) => <UserFunctionList {...props} />,
  }, {
    path: ROUTES.WORK_SHIFT_COPY,
    title: I18n.t('BEE917' /* Turno de Trabalho */),
    component: (props) => <WorkShiftForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.WORK_SHIFT_DETAIL,
    title: I18n.t('BEE917' /* Turno de Trabalho */),
    component: (props) => <WorkShiftForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.WORK_SHIFT_EDIT,
    title: I18n.t('BEE917' /* Turno de Trabalho */),
    component: (props) => <WorkShiftForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.WORK_SHIFT_CREATE,
    title: I18n.t('BEE917' /* Turno de Trabalho */),
    component: (props) => <WorkShiftForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.WORK_SHIFT_LIST,
    title: I18n.t('BEE869' /* Turnos de Trabalho */),
    component: (props) => <WorkShiftList {...props} />,
  }, {
    path: ROUTES.INDIRECT_TASKS_COPY,
    title: I18n.t('BEE1668' /* Tarefas Indiretas */),
    component: (props) => <IndirectTaskForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.INDIRECT_TASKS_DETAIL,
    title: I18n.t('BEE1668' /* Tarefas Indiretas */),
    component: (props) => <IndirectTaskForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.INDIRECT_TASKS_EDIT,
    title: I18n.t('BEE1668' /* Tarefas Indiretas */),
    component: (props) => <IndirectTaskForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.INDIRECT_TASKS_CREATE,
    title: I18n.t('BEE1668' /* Tarefas Indiretas */),
    component: (props) => <IndirectTaskForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.INDIRECT_TASKS_LIST,
    title: I18n.t('BEE1668' /* Tarefas Indiretas */),
    component: (props) => <IndirectTaskList {...props} />,
  }, {
    path: ROUTES.PRODUCT_COPY,
    title: I18n.t('BEE225' /* Produto */),
    component: (props) => <ProductForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.PRODUCT_DETAIL,
    title: I18n.t('BEE225' /* Produto */),
    component: (props) => <ProductForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.PRODUCT_EDIT,
    title: I18n.t('BEE225' /* Produto */),
    component: (props) => <ProductForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.PRODUCT_CREATE,
    title: I18n.t('BEE225' /* Produto */),
    component: (props) => <ProductForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.PRODUCT_LIST,
    title: I18n.t('BEE225' /* Produto */),
    component: (props) => <ProductList {...props} />,
  }, {
    path: ROUTES.MATERIAL_FAMILY_COPY,
    title: I18n.t('BEE1080' /* Família de Materiais */),
    component: (props) => <MaterialFamilyForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.MATERIAL_FAMILY_DETAIL,
    title: I18n.t('BEE1080' /* Família de Materiais */),
    component: (props) => <MaterialFamilyForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.MATERIAL_FAMILY_EDIT,
    title: I18n.t('BEE1080' /* Família de Materiais */),
    component: (props) => <MaterialFamilyForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.MATERIAL_FAMILY_CREATE,
    title: I18n.t('BEE1080' /* Família de Materiais */),
    component: (props) => <MaterialFamilyForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.MATERIAL_FAMILY_LIST,
    title: I18n.t('BEE1080' /* Família de Materiais */),
    component: (props) => <MaterialFamilyList {...props} />,
  }, {
    path: ROUTES.COMPANY_COPY,
    title: I18n.t('BEE112' /* Empresa */),
    component: (props) => <CompanyForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.COMPANY_DETAIL,
    title: I18n.t('BEE112' /* Empresa */),
    component: (props) => <CompanyForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.COMPANY_EDIT,
    title: I18n.t('BEE112' /* Empresa */),
    component: (props) => <CompanyForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.COMPANY_CREATE,
    title: I18n.t('BEE112' /* Empresa */),
    component: (props) => <CompanyForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.COMPANY_LIST,
    title: I18n.t('BEE112' /* Empresa */),
    component: (props) => <CompanyList {...props} />,
  }, {
    path: ROUTES.BRANCH_COPY,
    title: I18n.t('BEE145' /* Filial */),
    component: (props) => <BranchForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.BRANCH_DETAIL,
    title: I18n.t('BEE145' /* Filial */),
    component: (props) => <BranchForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.BRANCH_EDIT,
    title: I18n.t('BEE145' /* Filial */),
    component: (props) => <BranchForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.BRANCH_CREATE,
    title: I18n.t('BEE145' /* Filial */),
    component: (props) => <BranchForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.BRANCH_LIST,
    title: I18n.t('BEE145' /* Filial */),
    component: (props) => <BranchList {...props} />,
  }, {
    path: ROUTES.JOBS_LIST,
    title: I18n.t('BEE2210' /* Jobs */),
    component: (props) => <JobsList {...props} />,
  }, {
    path: ROUTES.JOBS_EDIT,
    title: I18n.t('BEE2210' /* Jobs */),
    component: (props) => <JobsForm isEdit {...props} />,
  }, {
    path: ROUTES.BRANCH_SETTINGS,
    title: I18n.t('BEE439' /* Parâmetros */),
    component: (props) => <BranchSettingsForm {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.WAREHOUSE_COPY,
    title: I18n.t('BEE156' /* Armazém */),
    component: (props) => <WarehouseForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.WAREHOUSE_DETAIL,
    title: I18n.t('BEE156' /* Armazém */),
    component: (props) => <WarehouseForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.WAREHOUSE_EDIT,
    title: I18n.t('BEE156' /* Armazém */),
    component: (props) => <WarehouseForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.WAREHOUSE_CREATE,
    title: I18n.t('BEE156' /* Armazém */),
    component: (props) => <WarehouseForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.WAREHOUSE_LIST,
    title: I18n.t('BEE156' /* Armazém */),
    component: (props) => <WarehouseList {...props} />,
  }, {
    path: ROUTES.RANGE_COPY,
    title: I18n.t('BEE594' /* Ranges */),
    component: (props) => <RangeForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.RANGE_DETAIL,
    title: I18n.t('BEE594' /* Ranges */),
    component: (props) => <RangeForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.RANGE_EDIT,
    title: I18n.t('BEE594' /* Ranges */),
    component: (props) => <RangeForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.RANGE_CREATE,
    title: I18n.t('BEE594' /* Ranges */),
    component: (props) => <RangeForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.RANGE_LIST,
    title: I18n.t('BEE594' /* Ranges */),
    component: (props) => <RangeList {...props} />,
  }, {
    path: ROUTES.DEPOSIT_COPY,
    title: I18n.t('BEE182' /* Depósito */),
    component: (props) => <DepositForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEPOSIT_DETAIL,
    title: I18n.t('BEE182' /* Depósito */),
    component: (props) => <DepositForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEPOSIT_EDIT,
    title: I18n.t('BEE182' /* Depósito */),
    component: (props) => <DepositForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEPOSIT_CREATE,
    title: I18n.t('BEE182' /* Depósito */),
    component: (props) => <DepositForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEPOSIT_LIST,
    title: I18n.t('BEE182' /* Depósito */),
    component: (props) => <DepositList {...props} />,
  }, {
    path: ROUTES.DEVELOP_I18N_LIST,
    title: I18n.t('BEE49' /* Idiomas */),
    component: (props) => <DevelopI18nList {...props} />,
  }, {
    path: ROUTES.DEVELOP_I18N_CREATE,
    title: I18n.t('BEE49' /* Idiomas */),
    component: (props) => <DevelopI18nForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEVELOP_I18N_EDIT,
    title: I18n.t('BEE49' /* Idiomas */),
    component: (props) => <DevelopI18nForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEVELOP_AGENT_LIST,
    title: I18n.t('BEE3465' /* Agentes */),
    component: (props) => <DevelopAgentList {...props} />,
  }, {
    path: ROUTES.DEVELOP_AGENT_DETAIL,
    title: I18n.t('BEE3465' /* Agentes */),
    component: (props) => <DevelopAgentForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEVELOP_AGENT_CREATE,
    title: I18n.t('BEE3465' /* Agentes */),
    component: (props) => <DevelopAgentForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEVELOP_AGENT_EDIT,
    title: I18n.t('BEE3465' /* Agentes */),
    component: (props) => <DevelopAgentForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEVELOP_ACCESS_PROFILE_LIST,
    title: I18n.t('BEE247' /* Perfil de Acesso */),
    component: (props) => <DevelopAccessProfileList {...props} />,
  }, {
    path: ROUTES.DEVELOP_ACCESS_PROFILE_CREATE,
    title: I18n.t('BEE247' /* Perfil de Acesso */),
    component: (props) => <DevelopAccessProfileForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEVELOP_ACCESS_PROFILE_EDIT,
    title: I18n.t('BEE247' /* Perfil de Acesso */),
    component: (props) => <DevelopAccessProfileForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DEVELOP_ACCESS_PROFILE_MANAGER,
    title: I18n.t('BEE247' /* Perfil de Acesso */),
    component: (props) => <DevelopAccessProfileManager {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DOCKS_COPY,
    title: I18n.t('BEE199' /* Doca */),
    component: (props) => <DockForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DOCKS_DETAIL,
    title: I18n.t('BEE199' /* Doca */),
    component: (props) => <DockForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DOCKS_EDIT,
    title: I18n.t('BEE199' /* Doca */),
    component: (props) => <DockForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DOCKS_CREATE,
    title: I18n.t('BEE199' /* Doca */),
    component: (props) => <DockForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.DOCKS_LIST,
    title: I18n.t('BEE199' /* Doca */),
    component: (props) => <DockList {...props} />,
  }, {
    path: ROUTES.DOCKS_LINK_CARRIER,
    title: I18n.t('BEE199' /* Doca */),
    component: (props) => <DockLinkCarrierForm {...props} />,
  }, {
    path: ROUTES.PRINTER_COPY,
    title: I18n.t('BEE328' /* Impressora */),
    component: (props) => <PrinterForm isCopy {...props} />,
  }, {
    path: ROUTES.PRINTER_DETAIL,
    title: I18n.t('BEE328' /* Impressora */),
    component: (props) => <PrinterForm isDetail {...props} />,
  }, {
    path: ROUTES.PRINTER_EDIT,
    title: I18n.t('BEE328' /* Impressora */),
    component: (props) => <PrinterForm isEdit {...props} />,
  }, {
    path: ROUTES.PRINTER_CREATE,
    title: I18n.t('BEE328' /* Impressora */),
    component: (props) => <PrinterForm isCreate {...props} />,
  }, {
    path: ROUTES.PRINTER_LIST,
    title: I18n.t('BEE328' /* Impressora */),
    component: (props) => <PrinterList {...props} />,
  }, {
    path: ROUTES.COLLECTOR_COPY,
    title: I18n.t('BEE1697' /* Coletor */),
    component: (props) => <CollectorForm isCopy {...props} />,
  }, {
    path: ROUTES.COLLECTOR_DETAIL,
    title: I18n.t('BEE1697' /* Coletor */),
    component: (props) => <CollectorForm isDetail {...props} />,
  }, {
    path: ROUTES.COLLECTOR_EDIT,
    title: I18n.t('BEE1697' /* Coletor */),
    component: (props) => <CollectorForm isEdit {...props} />,
  }, {
    path: ROUTES.COLLECTOR_CREATE,
    title: I18n.t('BEE1697' /* Coletor */),
    component: (props) => <CollectorForm isCreate {...props} />,
  }, {
    path: ROUTES.COLLECTOR_LIST,
    title: I18n.t('BEE1697' /* Coletor */),
    component: (props) => <CollectorList {...props} />,
  }, {
    path: ROUTES.TRANSPORT_EQUIP_LIST,
    title: I18n.t('BEE1397' /* Equipamentos de Transportes */),
    component: (props) => <TransportEquipmentList {...props} />,
  }, {
    path: ROUTES.TRANSPORT_EQUIP_DETAIL,
    title: I18n.t('BEE1397' /* Equipamentos de Transportes */),
    component: (props) => <TransportEquipmentForm isDetail {...props} />,
  }, {
    path: ROUTES.TRANSPORT_EQUIP_EDIT,
    title: I18n.t('BEE1397' /* Equipamentos de Transportes */),
    component: (props) => <TransportEquipmentForm isEdit {...props} />,
  }, {
    path: ROUTES.TRANSPORT_EQUIP_COPY,
    title: I18n.t('BEE1397' /* Equipamentos de Transportes */),
    component: (props) => <TransportEquipmentForm isCopy {...props} />,
  }, {
    path: ROUTES.TRANSPORT_EQUIP_CREATE,
    title: I18n.t('BEE1397' /* Equipamentos de Transportes */),
    component: (props) => <TransportEquipmentForm isCreate {...props} />,
  }, {
    path: ROUTES.INDUSTRIAL_SCALE_COPY,
    title: I18n.t('BEE3846' /* Balanças */),
    component: (props) => <IndustrialScaleForm isCopy {...props} />,
  }, {
    path: ROUTES.INDUSTRIAL_SCALE_DETAIL,
    title: I18n.t('BEE3846' /* Balanças */),
    component: (props) => <IndustrialScaleForm isDetail {...props} />,
  }, {
    path: ROUTES.INDUSTRIAL_SCALE_EDIT,
    title: I18n.t('BEE3846' /* Balanças */),
    component: (props) => <IndustrialScaleForm isEdit {...props} />,
  }, {
    path: ROUTES.INDUSTRIAL_SCALE_CREATE,
    title: I18n.t('BEE3846' /* Balanças */),
    component: (props) => <IndustrialScaleForm isCreate {...props} />,
  }, {
    path: ROUTES.INDUSTRIAL_SCALE_LIST,
    title: I18n.t('BEE3846' /* Balança */),
    component: (props) => <IndustrialScaleList {...props} />,
  }, {
    path: ROUTES.CARRIER_COPY,
    title: I18n.t('BEE301' /* Transportadora */),
    component: (props) => <CarrierForm isCopy {...props} />,
  }, {
    path: ROUTES.CARRIER_DETAIL,
    title: I18n.t('BEE301' /* Transportadora */),
    component: (props) => <CarrierForm isDetail {...props} />,
  }, {
    path: ROUTES.CARRIER_EDIT,
    title: I18n.t('BEE301' /* Transportadora */),
    component: (props) => <CarrierForm isEdit {...props} />,
  }, {
    path: ROUTES.CARRIER_CREATE,
    title: I18n.t('BEE301' /* Transportadora */),
    component: (props) => <CarrierForm isCreate {...props} />,
  }, {
    path: ROUTES.CARRIER_LIST,
    title: I18n.t('BEE301' /* Transportadora */),
    component: (props) => <CarrierList {...props} />,
  }, {
    path: ROUTES.CARRIER_LINK_DOCKS,
    title: I18n.t('BEE1081' /* Vincular Docas */),
    component: (props) => <CarrierLinkDocksForm {...props} />,
  }, {
    path: ROUTES.PRIORITY_COPY,
    title: I18n.t('BEE626' /* Prioridades */),
    component: (props) => <PriorityForm isCopy {...props} />,
  }, {
    path: ROUTES.PRIORITY_DETAIL,
    title: I18n.t('BEE626' /* Prioridades */),
    component: (props) => <PriorityForm isDetail {...props} />,
  }, {
    path: ROUTES.PRIORITY_EDIT,
    title: I18n.t('BEE626' /* Prioridades */),
    component: (props) => <PriorityForm isEdit {...props} />,
  }, {
    path: ROUTES.PRIORITY_CREATE,
    title: I18n.t('BEE626' /* Prioridades */),
    component: (props) => <PriorityForm isCreate {...props} />,
  }, {
    path: ROUTES.PRIORITY_LIST,
    title: I18n.t('BEE626' /* Prioridades */),
    component: (props) => <PriorityList {...props} />,
  }, {
    path: ROUTES.SCHEDULE_COPY,
    title: I18n.t('BEE658' /* Agendamentos */),
    component: (props) => <ScheduleForm isCopy {...props} />,
  }, {
    path: ROUTES.SCHEDULE_DETAIL,
    title: I18n.t('BEE658' /* Agendamentos */),
    component: (props) => <ScheduleForm isDetail {...props} />,
  }, {
    path: ROUTES.SCHEDULE_EDIT,
    title: I18n.t('BEE658' /* Agendamentos */),
    component: (props) => <ScheduleForm isEdit {...props} />,
  }, {
    path: ROUTES.SCHEDULE_CREATE,
    title: I18n.t('BEE658' /* Agendamentos */),
    component: (props) => <ScheduleForm isCreate {...props} />,
  }, {
    path: ROUTES.SCHEDULE_LIST,
    title: I18n.t('BEE658' /* Agendamentos */),
    component: (props) => <ScheduleList {...props} />,
  }, {
    path: ROUTES.CUSTOMER_COPY,
    title: I18n.t('BEE50' /* Cliente */),
    component: (props) => <CustomerForm isCopy {...props} />,
  }, {
    path: ROUTES.CUSTOMER_DETAIL,
    title: I18n.t('BEE50' /* Cliente */),
    component: (props) => <CustomerForm isDetail {...props} />,
  }, {
    path: ROUTES.CUSTOMER_EDIT,
    title: I18n.t('BEE50' /* Cliente */),
    component: (props) => <CustomerForm isEdit {...props} />,
  }, {
    path: ROUTES.CUSTOMER_CREATE,
    title: I18n.t('BEE50' /* Cliente */),
    component: (props) => <CustomerForm isCreate {...props} />,
  }, {
    path: ROUTES.CUSTOMER_LIST,
    title: I18n.t('BEE50' /* Cliente */),
    component: (props) => <CustomerList {...props} />,
  }, {
    path: ROUTES.CUSTOMER_BRANCH_LIST,
    title: I18n.t('BEE2020' /* Cliente x Filial */),
    component: (props) => <CustomerBranchList {...props} />,
  }, {
    path: ROUTES.CUSTOMER_BRANCH_COPY,
    title: I18n.t('BEE2020' /* Cliente x Filial */),
    component: (props) => <CustomerBranchForm isCopy {...props} />,
  }, {
    path: ROUTES.CUSTOMER_BRANCH_DETAIL,
    title: I18n.t('BEE2020' /* Cliente x Filial */),
    component: (props) => <CustomerBranchForm isDetail {...props} />,
  }, {
    path: ROUTES.CUSTOMER_BRANCH_EDIT,
    title: I18n.t('BEE2020' /* Cliente x Filial */),
    component: (props) => <CustomerBranchForm isEdit {...props} />,
  }, {
    path: ROUTES.CUSTOMER_BRANCH_CREATE,
    title: I18n.t('BEE2020' /* Cliente x Filial */),
    component: (props) => <CustomerBranchForm isCreate {...props} />,
  }, {
    path: ROUTES.SUPPLIER_COPY,
    title: I18n.t('BEE342' /* Fornecedor */),
    component: (props) => <SupplierForm isCopy {...props} />,
  }, {
    path: ROUTES.SUPPLIER_DETAIL,
    title: I18n.t('BEE342' /* Fornecedor */),
    component: (props) => <SupplierForm isDetail {...props} />,
  }, {
    path: ROUTES.SUPPLIER_EDIT,
    title: I18n.t('BEE342' /* Fornecedor */),
    component: (props) => <SupplierForm isEdit {...props} />,
  }, {
    path: ROUTES.SUPPLIER_CREATE,
    title: I18n.t('BEE342' /* Fornecedor */),
    component: (props) => <SupplierForm isCreate {...props} />,
  }, {
    path: ROUTES.SUPPLIER_LIST,
    title: I18n.t('BEE342' /* Fornecedor */),
    component: (props) => <SupplierList {...props} />,
  }, {
    path: ROUTES.ADDRESS_COPY,
    title: I18n.t('BEE496' /* Endereço Armazenamento */),
    component: (props) => <StorageAddressFormMain isCopy {...props} />,
  }, {
    path: ROUTES.ADDRESS_DETAIL,
    title: I18n.t('BEE496' /* Endereço Armazenamento */),
    component: (props) => <StorageAddressFormMain isDetail {...props} />,
  }, {
    path: ROUTES.ADDRESS_EDIT,
    title: I18n.t('BEE496' /* Endereço Armazenamento */),
    component: (props) => <StorageAddressFormMain isEdit {...props} />,
  }, {
    path: ROUTES.ADDRESS_CREATE,
    title: I18n.t('BEE496' /* Endereço Armazenamento */),
    component: (props) => <StorageAddressFormMain isCreate {...props} />,
  }, {
    path: ROUTES.GENERATE_ADDRESSES,
    title: I18n.t('BEE496' /* Endereço Armazenamento */),
    component: (props) => <StorageAddressForm {...props} />,
  }, {
    path: ROUTES.ADDRESS_BULK_BLOCK,
    title: I18n.t('BEE496' /* Endereço Armazenamento */),
    component: (props) => <MassBlockList {...props} />,
  }, {
    path: ROUTES.ADDRESS_LIST,
    title: I18n.t('BEE496' /* Endereço Armazenamento */),
    component: (props) => <StorageAddressList {...props} />,
  }, {
    path: ROUTES.CURVES_LIST,
    title: I18n.t('BEE2463' /* Curvas */),
    component: (props) => <CurvesList {...props} />,
  }, {
    path: ROUTES.CURVE_CREATE,
    title: I18n.t('BEE496' /* Curvas */),
    component: (props) => <CurveForm isCreate {...props} />,
  }, {
    path: ROUTES.CURVE_EDIT,
    title: I18n.t('BEE496' /* Curvas */),
    component: (props) => <CurveForm isEdit {...props} />,
  }, {
    path: ROUTES.ADDRESS_SIZE_LIST,
    title: I18n.t('BEE1491' /* Tamanho de Endereço */),
    component: (props) => <StorageAddressSizeList {...props} />,
  }, {
    path: ROUTES.ADDRESS_SISE_DETAIL,
    title: I18n.t('BEE1491' /* Tamanho de Endereço */),
    component: (props) => <StorageAddressSizeForm isDetail {...props} />,
  }, {
    path: ROUTES.ADDRESS_SIZE_EDIT,
    title: I18n.t('BEE1491' /* Tamanho de Endereço */),
    component: (props) => <StorageAddressSizeForm isEdit {...props} />,
  }, {
    path: ROUTES.ADDRESS_SIZE_COPY,
    title: I18n.t('BEE1491' /* Tamanho de Endereço */),
    component: (props) => <StorageAddressSizeForm isCopy {...props} />,
  }, {
    path: ROUTES.ADDRESS_SIZE_CREATE,
    title: I18n.t('BEE1491' /* Tamanho de Endereço */),
    component: (props) => <StorageAddressSizeForm isCreate {...props} />,
  }, {
    path: ROUTES.ADDRESS_TYPE_COPY,
    title: I18n.t('BEE732' /* Tipos de Endereço */),
    component: (props) => <StorageAddressTypeForm isCopy {...props} />,
  }, {
    path: ROUTES.ADDRESS_TYPE_DETAIL,
    title: I18n.t('BEE732' /* Tipos de Endereço */),
    component: (props) => <StorageAddressTypeForm isDetail {...props} />,
  }, {
    path: ROUTES.ADDRESS_TYPE_EDIT,
    title: I18n.t('BEE732' /* Tipos de Endereço */),
    component: (props) => <StorageAddressTypeForm isEdit {...props} />,
  }, {
    path: ROUTES.ADDRESS_TYPE_CREATE,
    title: I18n.t('BEE732' /* Tipos de Endereço */),
    component: (props) => <StorageAddressTypeForm isCreate {...props} />,
  }, {
    path: ROUTES.ADDRESS_TYPE_LIST,
    title: I18n.t('BEE732' /* Tipos de Endereço */),
    component: (props) => <StorageAddressTypeList {...props} />,
  }, {
    path: ROUTES.PACKAGE_COPY,
    title: I18n.t('BEE497' /* Embalagem */),
    component: (props) => <PackageForm isCopy {...props} />,
  }, {
    path: ROUTES.PACKAGE_DETAIL,
    title: I18n.t('BEE497' /* Embalagem */),
    component: (props) => <PackageForm isDetail {...props} />,
  }, {
    path: ROUTES.PACKAGE_EDIT,
    title: I18n.t('BEE497' /* Embalagem */),
    component: (props) => <PackageForm isEdit {...props} />,
  }, {
    path: ROUTES.PACKAGE_CREATE,
    title: I18n.t('BEE497' /* Embalagem */),
    component: (props) => <PackageForm isCreate {...props} />,
  }, {
    path: ROUTES.PACKAGE_LIST,
    title: I18n.t('BEE497' /* Embalagem */),
    component: (props) => <PackageList {...props} />,
  }, {
    path: ROUTES.UNIT_COPY,
    title: I18n.t('BEE29' /* Unidades de Medida */),
    component: (props) => <UnitForm isCopy {...props} />,
  }, {
    path: ROUTES.UNIT_DETAIL,
    title: I18n.t('BEE29' /* Unidades de Medida */),
    component: (props) => <UnitForm isDetail {...props} />,
  }, {
    path: ROUTES.UNIT_EDIT,
    title: I18n.t('BEE29' /* Unidades de Medida */),
    component: (props) => <UnitForm isEdit {...props} />,
  }, {
    path: ROUTES.UNIT_CREATE,
    title: I18n.t('BEE29' /* Unidades de Medida */),
    component: (props) => <UnitForm isCreate {...props} />,
  }, {
    path: ROUTES.UNIT_LIST,
    title: I18n.t('BEE29' /* Unidades de Medida */),
    component: (props) => <UnitList {...props} />,
  },
  // etiqueta
  {
    path: ROUTES.MOVEMENT_LABEL_LOT,
    title: I18n.t('BEE1380' /* Etiqueta Lote */),
    component: (props) => <LotLabelForm {...props} />,
  }, {
    path: ROUTES.MOVEMENT_LABEL_EAN,
    title: I18n.t('BEE1372' /* Etiqueta EAN */),
    component: (props) => <LabelEanForm {...props} />,
  }, {
    path: ROUTES.MOVEMENT_LABEL_ADDRESS,
    title: I18n.t('BEE1405' /* Etiqueta Endereço */),
    component: (props) => <LabelAddressForm {...props} />,
  }, {
    path: ROUTES.MOVEMENT_LABEL_VOLUME,
    title: I18n.t('BEE1922' /* Etiqueta Volume */),
    component: (props) => <LabelVolumeForm {...props} />,
  }, {
    path: ROUTES.MOVEMENT_LABEL_KARDEXCABLECUT,
    title: I18n.t('BEE1407' /* Etiqueta Corte de Cabos */),
    component: (props) => <LabelKardexCableCutForm {...props} />,
  }, {
    path: ROUTES.EAN_COPY,
    title: I18n.t('BEE377' /* EAN */),
    component: (props) => <EanForm isCopy {...props} />,
  }, {
    path: ROUTES.EAN_DETAIL,
    title: I18n.t('BEE377' /* EAN */),
    component: (props) => <EanForm isDetail {...props} />,
  }, {
    path: ROUTES.EAN_EDIT,
    title: I18n.t('BEE377' /* EAN */),
    component: (props) => <EanForm isEdit {...props} />,
  }, {
    path: ROUTES.EAN_CREATE,
    title: I18n.t('BEE377' /* EAN */),
    component: (props) => <EanForm isCreate {...props} />,
  }, {
    path: ROUTES.EAN_LIST,
    title: I18n.t('BEE377' /* EAN */),
    component: (props) => <EanList {...props} />,
  }, {
    path: ROUTES.CUSTOMER_PRODUCT_COPY,
    title: I18n.t('BEE380' /* Produtos x Cliente */),
    component: (props) => <CustomerProductForm isCopy {...props} />,
  }, {
    path: ROUTES.CUSTOMER_PRODUCT_DETAIL,
    title: I18n.t('BEE380' /* Produtos x Cliente */),
    component: (props) => <CustomerProductForm isDetail {...props} />,
  }, {
    path: ROUTES.CUSTOMER_PRODUCT_EDIT,
    title: I18n.t('BEE380' /* Produtos x Cliente */),
    component: (props) => <CustomerProductForm isEdit {...props} />,
  }, {
    path: ROUTES.CUSTOMER_PRODUCT_CREATE,
    title: I18n.t('BEE380' /* Produtos x Cliente */),
    component: (props) => <CustomerProductForm isCreate {...props} />,
  }, {
    path: ROUTES.CUSTOMER_PRODUCT_LIST,
    title: I18n.t('BEE380' /* Produtos x Cliente */),
    component: (props) => <CustomerProductList {...props} />,
  }, {
    path: ROUTES.PRODUCT_BRANCH_COPY,
    title: I18n.t('BEE408' /* Produtos x Filial */),
    component: (props) => <ProductBranchForm isCopy {...props} />,
  }, {
    path: ROUTES.PRODUCT_BRANCH_DETAIL,
    title: I18n.t('BEE408' /* Produtos x Filial */),
    component: (props) => <ProductBranchForm isDetail {...props} />,
  }, {
    path: ROUTES.PRODUCT_BRANCH_EDIT,
    title: I18n.t('BEE408' /* Produtos x Filial */),
    component: (props) => <ProductBranchForm isEdit {...props} />,
  }, {
    path: ROUTES.PRODUCT_BRANCH_CREATE,
    title: I18n.t('BEE408' /* Produtos x Filial */),
    component: (props) => <ProductBranchForm isCreate {...props} />,
  }, {
    path: ROUTES.ORDERS_TYPE_COPY,
    title: I18n.t('BEE2796' /* Tipos de Documentos */),
    component: (props) => <TypeOrdersForm isCopy {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.ORDERS_TYPE_DETAIL,
    title: I18n.t('BEE2796' /* Tipos de Documentos */),
    component: (props) => <TypeOrdersForm isDetail {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.ORDERS_TYPE_EDIT,
    title: I18n.t('BEE2796' /* Tipos de Documentos */),
    component: (props) => <TypeOrdersForm isEdit {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.ORDERS_TYPE_CREATE,
    title: I18n.t('BEE2796' /* Tipos de Documentos */),
    component: (props) => <TypeOrdersForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.ORDERS_TYPE_LIST,
    title: I18n.t('BEE2796' /* Tipos de Documentos */),
    component: (props) => <TypeOrdersList {...props} />,
  }, {
    path: ROUTES.PRODUCT_BRANCH_LIST,
    title: I18n.t('BEE408' /* Produtos x Filial */),
    component: (props) => <ProductBranchList {...props} />,
  }, {
    path: ROUTES.ENTRANCE_VOLUME_PLATE,
    title: I18n.t('BEE1438' /* Recepção NF-e Placa e Volumes */),
    component: (props) => <EntranceVolumePlate {...props} />,
  }, {
    path: ROUTES.ENTRANCE_PLATE_CHECK,
    title: I18n.t('BEE399' /* Conferência de Placa */),
    component: (props) => <PlateCheckForm {...props} />,
  }, {
    path: ROUTES.STOCK_INVENTORY_ERP,
    title: I18n.t('BEE429' /* Estoque ERP */),
    component: (props) => <InventoryErpList {...props} />,
  }, {
    path: ROUTES.STOCK_BALANCE_LIST,
    title: I18n.t('BEE489' /* Saldo Estoque */),
    component: (props) => <StockBalanceList {...props} />,
  }, {
    path: ROUTES.STOCK_TRANSFER_ADDRESS_FORM,
    title: I18n.t('BEE34' /* Transferência */),
    component: (props) => <StockTransferForm {...props} />,
  }, {
    path: ROUTES.DETAILED_STOCK_BALANCE,
    title: I18n.t('BEE1761' /* Saldo do Estoque - Detalhes */),
    component: (props) => <DetailedStockBalanceList {...props} />,
  }, {
    path: ROUTES.AVERAGE_COST,
    title: I18n.t('BEE410' /* Custo Médio */),
    component: (props) => <AverageCost {...props} />,
  }, {
    path: ROUTES.INBOUND_CHECK,
    title: I18n.t('BEE37' /* Conferência */),
    component: (props) => <InboundCheckForm {...props} />,
  }, {
    path: ROUTES.INBOUND_BAPTISM,
    title: I18n.t('BEE1255' /* Gerar Batismo */),
    component: (props) => <BaptismForm type={1} {...props} />,
  }, {
    path: ROUTES.INBOUND_STORAGE,
    title: I18n.t('BEE38' /* Armazenamento */),
    component: (props) => <InboundStorageForm {...props} />,
  }, {
    path: ROUTES.INBOUND_ORDER_LIST,
    title: I18n.t('BEE438' /* Documentos de Entrada */),
    component: (props) => <InboundOrderList {...props} />,
  }, {
    path: ROUTES.INBOUND_ORDER_DETAIL,
    title: I18n.t('BEE438' /* Documentos de Entrada */),
    component: (props) => <InboundOrderForm isDetail {...props} />,
  }, {
    path: ROUTES.INBOUND_ORDER_PANEL,
    title: I18n.t('BEE440' /* Monitor de Recebimento */),
    component: (props) => <InboundOrderPanel {...props} />,
  }, {
    path: ROUTES.INBOUND_ORDER_ADD,
    title: I18n.t('BEE1001' /* Adicionar documento de entrada */),
    component: (props) => <InboundOrderAdd {...props} />,
  }, {
    path: ROUTES.INBOUND_ORDER_EDIT,
    title: I18n.t('BEE1001' /* Adicionar documento de entrada */),
    component: (props) => <InboundOrderAdd {...props} />,
  }, {
    path: ROUTES.INBOUND_PRODUCT_MONITOR,
    title: I18n.t('BEE1968' /* Monitor de Produtos do Recebimento */),
    component: (props) => <InboundProductMonitor {...props} />,
  }, {
    path: ROUTES.STOCK_MOVEMENT_LIST,
    title: I18n.t('BEE525' /* Movimentos Estoque */),
    component: (props) => <StockMovementList {...props} />,
  }, {
    path: ROUTES.CONSULT_INBOUND_LIST,
    title: I18n.t('BEE438' /* Documentos de Entrada */),
    component: (props) => <InboundOrderList isConsult {...props} />,
  }, {
    path: ROUTES.CONSULT_INBOUND_DETAILS,
    title: I18n.t('BEE438' /* Documentos de Entrada */),
    component: (props) => <InboundOrderForm isDetail backConsult {...props} />,
  }, {
    path: ROUTES.CONSULT_OUTBOUND_CHECK,
    title: I18n.t('BEE438' /* Documentos de Entrada */),
    component: (props) => <ExpeditionCheckList {...props} />,
  }, {
    path: ROUTES.CONSULT_BAPTISM_LABEL_INBOUND,
    title: I18n.t('BEE1719' /* Etiqueta Batismo */),
    component: (props) => <BaptismLabelConsultInbound {...props} />,
  }, {
    path: ROUTES.CONSULT_BAPTISM_LABEL_OUTBOUND,
    title: I18n.t('BEE1719' /* Etiqueta Batismo */),
    component: (props) => <BaptismLabelConsultOutbound {...props} />,
  }, {
    path: ROUTES.CONSULT_OUTBOUND_ORDER_LIST,
    title: I18n.t('BEE2080' /* Consulta Documentos de Saída */),
    component: (props) => <OutboundOrderList isConsult {...props} />,
  }, {
    path: ROUTES.OUTBOUND_INDUSTRY_ORDER_LIST,
    title: I18n.t('BEE2829' /* Documento de Saída - Indústria */),
    component: (props) => <OutboundMonitorIndustry {...props} />,
  }, {
    path: ROUTES.CONSULT_OUTBOUND_ORDER_DETAIL,
    title: I18n.t('BEE2080' /* Consulta Documentos de Saída */),
    component: (props) => <OutboundOrderForm isConsult isDetail {...props} />,
  },
  // Outbound panel logo abaixo
  {
    path: ROUTES.OUTBOUND_MONITOR,
    title: I18n.t('BEE1363' /* Monitor de Expedição */),
    component: (props) => <OutboundMonitor {...props} />,
  }, {
    path: ROUTES.PENDING_ALLOCATION_MONITOR,
    title: I18n.t('BEE1857' /* Monitor de Pendências de Alocação */),
    component: (props) => <OutboundPendingAllocationMonitor {...props} />,
  }, {
    path: ROUTES.OUTBOUND_BAPTISM,
    title: I18n.t('BEE1255' /* Gerar Batismo */),
    component: (props) => <BaptismForm type={2} {...props} />,
  }, {
    path: ROUTES.OUTBOUND_ORDER_LIST,
    title: I18n.t('BEE1087' /* Documentos de Saída */),
    component: (props) => <OutboundOrderList {...props} />,
  }, {
    path: ROUTES.OUTBOUND_ORDER_DETAIL,
    title: I18n.t('BEE1087' /* Documentos de Saída */),
    component: (props) => <OutboundOrderForm isDetail {...props} />,
  }, {
    path: ROUTES.OUTBOUND_WAVE_CREATE,
    title: I18n.t('BEE1445' /* Gerar Onda */),
    component: (props) => <OutboundWaveForm {...props} />,
  }, {
    path: ROUTES.OUTBOUND_WAVE_PICKING,
    title: I18n.t('BEE1446' /* Separação por Onda */),
    component: (props) => <OutboundOrderPickingWave {...props} />,
  }, {
    path: ROUTES.OUTBOUND_PICKING,
    title: I18n.t('BEE1447' /* Separação por Documento */),
    component: (props) => <OutboundOrderPicking {...props} />,
  }, {
    path: ROUTES.OUTBOUND_CHECK,
    title: I18n.t('BEE37' /* Conferência */),
    component: (props) => <OutboundOrderCheck {...props} />,
  }, {
    path: ROUTES.OUTBOUND_VOLUME_CHECK,
    title: I18n.t('BEE757' /* Conferência de Volume */),
    component: (props) => <VolumeCheckForm {...props} />,
  }, {
    path: ROUTES.OUTBOUND_PICKING_RETURN,
    title: I18n.t('BEE908' /* Devolução de Picking */),
    component: (props) => <PickingReturn {...props} />,
  }, {
    path: ROUTES.OUTBOUND_DEVOLUTION_MONITOR_LIST,
    title: I18n.t('BEE1921' /* Monitor de Devolução */),
    component: (props) => <ReturnPickingOrder {...props} />,
  }, {
    path: ROUTES.OUTBOUND_CABLE_CUT_PLAN,
    title: I18n.t('BEE1417' /* Plano de Corte de Cabos */),
    component: (props) => <CableCutPlan {...props} />,
  }, {
    path: ROUTES.OUTBOUND_CABLE_CUT_MONIT,
    title: I18n.t('BEE1419' /* Monitor de Cortes */),
    component: (props) => <CableCutMonitList {...props} />,
  }, {
    path: ROUTES.OUTBOUND_WAVE_MONITOR,
    title: I18n.t('BEE1882' /* Monitor de Onda */),
    component: (props) => <OutboundOrderWaveMonitor {...props} />,
  }, {
    path: ROUTES.OUTBOUND_CONSOLIDATION,
    title: I18n.t('BEE44' /* Consolidação */),
    component: (props) => <OutboundConsolidation {...props} />,
  }, {
    path: ROUTES.OUTBOUND_DOCKS,
    title: I18n.t('BEE19' /* Docas */),
    component: (props) => <OutboundDocks {...props} />,
  }, {
    path: ROUTES.OUTBOUND_DOCK_CONTROL_MONITOR,
    title: I18n.t('BEE2072' /* Monitor de Controle de Docas */),
    component: (props) => <DockControlMonitor {...props} />,
  }, {
    path: ROUTES.OUTBOUND_DOCK_VOLUME_REMOVAL_MONITOR,
    title: I18n.t('BEE3804' /* Monitor de Missões de Remoção de Volumes da Doca */),
    component: (props) => <DockVolumeRemovalMonitor {...props} />,
  }, {
    path: ROUTES.OUTBOUND_RESTRICTION_MONITOR,
    title: I18n.t('BEE2068' /* Monitor de Restrição */),
    component: (props) => <RestrictionMonitorList {...props} />,
  }, {
    path: ROUTES.OUTBOUND_PICKING_RESTRICTION_MONITOR,
    title: I18n.t('BEE2775' /* Monitor de Restrição de Separação */),
    component: (props) => <PickingRestrictionMonitorList {...props} />,
  }, {
    path: ROUTES.WAYBILL_MONITOR,
    title: I18n.t('BEE1369' /* Romaneio */),
    component: (props) => <WaybillMonitor {...props} />,
  }, {
    path: ROUTES.OUTBOUND_ALLOCATED_PRODUCT,
    title: I18n.t('BEE2007' /* Produtos Alocados */),
    component: (props) => <AllocatedProductList {...props} />,
  },
  {
    path: ROUTES.OUTBOUND_CONFIRM_DELIVERY,
    title: I18n.t('BEE3680' /* Confirmar Entrega */),
    component: (props) => <ConfirmDeliveryList {...props} />,
  },
  // Reports
  {
    path: ROUTES.REPORT_COMPANY_LIST,
    title: I18n.t('BEE15' /* Empresas */),
    component: (props) => <ReportAdminGeneralCompany {...props} />,
  }, {
    path: ROUTES.REPORT_BRANCH_LIST,
    title: I18n.t('BEE145' /* Filial */),
    component: (props) => <ReportAdminGeneralBranch {...props} />,
  }, {
    path: ROUTES.REPORT_WAREHOUSE_LIST,
    title: I18n.t('BEE17' /* Armazéns */),
    component: (props) => <ReportAdminGeneralWarwhouse {...props} />,
  }, {
    path: ROUTES.REPORT_DEPOSIT_LIST,
    title: I18n.t('BEE182' /* Depósitos */),
    component: (props) => <ReportAdminGeneralDeposit {...props} />,
  }, {
    path: ROUTES.REPORT_DOCKS_LIST,
    title: I18n.t('BEE19' /* Docas */),
    component: (props) => <ReportAdminGeneralDock {...props} />,
  }, {
    path: ROUTES.REPORT_CUSTOMER_LIST,
    title: I18n.t('BEE22' /* Clientes */),
    component: (props) => <ReportAdminGeneralCustomer {...props} />,
  }, {
    path: ROUTES.REPORT_PRODUCT_LIST,
    title: I18n.t('BEE27' /* Produtos */),
    component: (props) => <ReportAdminProductProducts {...props} />,
  }, {
    path: ROUTES.REPORT_EAN_LIST,
    title: I18n.t('BEE377' /* EAN */),
    component: (props) => <ReportAdminProductEan {...props} />,
  }, {
    path: ROUTES.REPORT_PACKING_LIST,
    title: I18n.t('BEE28' /* Embalagens */),
    component: (props) => <ReportAdminProductPacking {...props} />,
  }, {
    path: ROUTES.REPORT_SUPPLIER_LIST,
    title: I18n.t('BEE23' /* Fornecedores */),
    component: (props) => <ReportAdminSupplier {...props} />,
  }, {
    path: ROUTES.REPORT_ADDRESS_LIST,
    title: I18n.t('BEE20' /* Endereços */),
    component: (props) => <ReportAdminGeneralStorageAddress {...props} />,
  }, {
    path: ROUTES.REPORT_CURVES_LIST,
    title: I18n.t('BEE2463' /* Curvas */),
    component: (props) => <ReportCurves {...props} />,
  }, {
    path: ROUTES.REPORT_PRODUCTS_BRANCH_LIST,
    title: I18n.t('BEE408' /* Produtos por Filial */),
    component: (props) => <ReportProductBranch {...props} />,
  }, {
    path: ROUTES.REPORT_UNITS_MEASURE_LIST,
    title: I18n.t('BEE29' /* Unidades de Medida */),
    component: (props) => <ReportAdminProductUnitsMeasure {...props} />,
  }, {
    path: ROUTES.REPORT_PRODUCTS_CUSTOMER_LIST,
    title: I18n.t('BEE380' /* Produtos x Cliente */),
    component: (props) => <ReportAdminProductsCustomer {...props} />,
  }, {
    path: ROUTES.REPORT_USER_LIST,
    title: I18n.t('BEE25' /* Usuários */),
    component: (props) => <ReportAdminUserUsers {...props} />,
  }, {
    path: ROUTES.REPORT_PRINTER_LIST,
    title: I18n.t('BEE21' /* Impressoras */),
    component: (props) => <ReportAdminGeneralPrinter {...props} />,
  }, {
    path: ROUTES.REPORT_COLLECTOR,
    title: I18n.t('BEE30' /* Coletores */),
    component: (props) => <ReportAdminGeneralCollector {...props} />,
  }, {
    path: ROUTES.REPORT_TRANSPORT_EQUIP,
    title: I18n.t('BEE1397' /* Equipamentos de Transportes */),
    component: (props) => <ReportTransportEquipment {...props} />,
  }, {
    path: ROUTES.REPORT_CARRIER_LIST,
    title: I18n.t('BEE24' /* Transportadoras */),
    component: (props) => <ReportAdminGeneralCarrier {...props} />,
  }, {
    path: ROUTES.REPORT_TYPEADDRESS_LIST,
    title: I18n.t('BEE732' /* Tipos de Endereço */),
    component: (props) => <ReportRegistrationGeneralTypeAddress {...props} />,
  }, {
    path: ROUTES.REPORT_PRIORITIES_LIST,
    title: I18n.t('BEE626' /* Prioridades */),
    component: (props) => <ReportAdminGeneralPriorities {...props} />,
  }, {
    path: ROUTES.REPORT_STOCK_BALANCE_LIST,
    title: I18n.t('BEE489' /* Saldo Estoque */),
    component: (props) => <ReportStockStockBalance {...props} />,
  }, {
    path: ROUTES.REPORT_STOCK_EXPIRED_PRODUCTS,
    title: I18n.t('BEE2362' /* Produto Vencido */),
    component: (props) => <ReportStockExpiredProducts {...props} />,
  }, {
    path: ROUTES.REPORT_STOCK_EMPTY_ADDRESSES,
    title: I18n.t('BEE1753' /* Endereços Vazios */),
    component: (props) => <ReportStockEmptyAddresses {...props} />,
  }, {
    path: ROUTES.REPORT_STOCK_ALLOCATION_ADDRESS,
    title: I18n.t('BEE2030' /* Alocação de Endereço */),
    component: (props) => <ReportStockAllocationAddress {...props} />,
  }, {
    path: ROUTES.REPORT_RETAIL,
    title: I18n.t('BEE2239' /* Retalhos */),
    component: (props) => <ReportRetail {...props} />,
  }, {
    path: ROUTES.REPORT_PRODUCTS_SEPARATION_DATE,
    title: I18n.t('BEE2289' /* Separação de Produtos por Data */),
    component: (props) => <ReportProductsSeparationDate {...props} />,
  }, {
    path: ROUTES.REPORT_STOCK_ADRESS_MOVEMENT_LIST,
    title: I18n.t('BEE754' /* Movimento Estoque x Endereço */),
    component: (props) => <ReportStockMovementAdress {...props} />,
  }, {
    path: ROUTES.REPORT_INBOUND_ORDER_LIST,
    title: I18n.t('BEE438' /* Documentos de Entrada */),
    component: (props) => <ReportMovementInboundOrderList {...props} />,
  }, {
    path: ROUTES.REPORT_INBOUND_ORDERS_ITEMS_LIST,
    title: I18n.t('BEE2388' /* Itens de Documentos de Entrada */),
    component: (props) => <ReportMovementInboundOrdersItemsList {...props} />,
  }, {
    path: ROUTES.REPORT_RANGE_LIST,
    title: I18n.t('BEE1346' /* Range x Endereços x Usuários */),
    component: (props) => <ReportAdminGeneralRange {...props} />,
  }, {
    path: ROUTES.REPORT_RANGES_BY_USER_LIST,
    title: I18n.t('BEE2368' /* Ranges x Usuários */),
    component: (props) => <ReportAdminGeneralRangesByUser {...props} />,
  }, {
    path: ROUTES.REPORT_RANGES_LIST,
    title: I18n.t('BEE594' /* Ranges */),
    component: (props) => <ReportAdminGeneralRanges {...props} />,
  }, {
    path: ROUTES.REPORT_USERS_DEPARTMENTS,
    title: I18n.t('BEE731' /* Departamentos */),
    component: (props) => <ReportAdminUsersDepartments {...props} />,
  }, {
    path: ROUTES.REPORT_USERS_USERFUNCTIONS,
    title: I18n.t('BEE881' /* Funções do Usuário */),
    component: (props) => <ReportAdminUsersFunctions {...props} />,
  }, {
    path: ROUTES.REPORT_USERS_WORKTURNS,
    title: I18n.t('BEE869' /* Turnos de Trabalho */),
    component: (props) => <ReportAdminUsersWorkTurns {...props} />,
  }, {
    path: ROUTES.REPORT_USERS_INDIRECTTASKS_LIST,
    title: I18n.t('BEE1668' /* Tarefas Indiretas */),
    component: (props) => <ReportAdminUsersIndirectTasks {...props} />,
  }, {
    path: ROUTES.REPORT_RESUPPLY_PICKING,
    title: I18n.t('BEE2646' /* Ressuprimento de Picking */),
    component: (props) => <ReportResupplyPicking {...props} />,
  }, {
    path: ROUTES.INBOUND_ORDERS_DASHBOARD_OVERVIEW,
    title: I18n.t('BEE2530' /* Visão Geral Recebimento */),
    component: (props) => <ReceiptOverview {...props} />,
  }, {
    path: ROUTES.INBOUND_ORDERS_DASHBOARD,
    title: I18n.t('BEE1274' /* Dashboard Recebimento */),
    component: (props) => <InboundOrderDashboard {...props} />,
  }, {
    path: ROUTES.INBOUND_ORDERS_DASHBOARD_AVERAGE_TIME,
    title: I18n.t('BEE2557' /* Análise Tempos Médios */),
    component: (props) => <AverageTimes {...props} />,
  }, {
    path: ROUTES.OUTBOUND_ORDERS_DASHBOARD,
    title: I18n.t('BEE3591' /* Visões por Status */),
    component: (props) => <OutboundOrdersDashboard {...props} />,
  }, {
    path: ROUTES.OUTBOUND_ORDERS_DASHBOARD_OVERVIEW,
    title: I18n.t('BEE2546' /* Visão Geral Expedição */),
    component: (props) => <ExpeditionOverview {...props} />,
  }, {
    path: ROUTES.DASHBOARD_OUTBOUND_AVERAGE_TIME,
    title: I18n.t('BEE2517' /* Análise Tempos Médios */),
    component: (props) => <AverageTimeAnalysisDashboard {...props} />,
  }, {
    path: ROUTES.DASHBOARD_INVENTORY_PER_BRANCH,
    title: I18n.t('BEE2254' /* Dashboard Inventário */),
    component: (props) => <InventoryDashboardPerBranch {...props} />,
  }, {
    path: ROUTES.DASHBOARD_ADDRESS_OCCUPATION,
    title: I18n.t('BEE2458' /* Ocupação de Endereços */),
    component: (props) => <AddressOccupationDashboard {...props} />,
  }, {
    path: ROUTES.REPORT_MOVEMENT_CHECKQTYPRODUCTSRANGE_LIST,
    title: I18n.t('BEE1683' /* Conferência Qtd Produtos e Range */),
    component: (props) => <ReportMovementExpeditionCheckProductsRange {...props} />,
  }, {
    path: ROUTES.REPORT_MOVEMENT_EXPEDITION_OUTBOUND_ORDER_LIST,
    title: I18n.t('BEE1087' /* Documentos de Saída */),
    component: (props) => <ReportMovementExpeditionOutboundList {...props} />,
  }, {
    path: ROUTES.REPORT_MOVEMENT_EXPEDITION_OUTBOUND_ORDER_LOT,
    title: I18n.t('BEE3501' /* Documento de Saída - Rastreio Lote */),
    component: (props) => <ReportMovementExpeditionOutboundLot {...props} />,
  }, {
    path: ROUTES.REPORT_MOVEMENT_EXPEDITION_OUTBOUND_ORDERS_ITEMS_LIST,
    title: I18n.t('BEE2378' /* Itens de Documentos de Saída */),
    component: (props) => <ReportMovementExpeditionOutboundOrdersItemsList {...props} />,
  }, {
    path: ROUTES.REPORT_MOVEMENT_EXPEDITION_OUTBOUND_ORDERS_ITEMS_RANGE_LIST,
    title: I18n.t('BEE3934' /* Documentos de Saída - Produtos - Range */),
    component: (props) => <ReportMovementExpeditionOutboundOrdersItemsRangeList {...props} />,
  }, {
    path: ROUTES.REPORT_INBOUND_ORDER_PRE_ENTRY,
    title: I18n.t('BEE2279' /* Nota Fiscal Pré-Entrada */),
    component: (props) => <ReportMovementInboundOrderPreEntry {...props} />,
  }, {
    path: ROUTES.REPORT_VOLUME_DOCUMENT_LIST,
    title: I18n.t('BEE2270' /* Volume por Documento */),
    component: (props) => <ReportMovementExpeditionVolumeDocumentList {...props} />,
  }, {
    path: ROUTES.REPORT_MOVEMENT_EXPEDITION_ITEMS_PENDING_ALLOCATION,
    title: I18n.t('BEE3316' /* Produtos pendentes para alocação */),
    component: (props) => <ReportMovementExpeditionItemsPendingAllocation {...props} />,
  },
  {
    path: ROUTES.REPORT_KPIS_CHECKQTYPRODUCTSPERCHECKER_INBOUND,
    title: I18n.t('BEE1886' /* Conferência Recebimento Qtd Produtos por Conferente */),
    component: (props) => <ReportMovementExpeditionCheckQtyProductsPerCheckerInbound {...props} />,
  }, {
    path: ROUTES.REPORT_KPIS_CHECKQTYPRODUCTSPERCHECKER_OUTBOUND,
    title: I18n.t('BEE1993' /* Conferência Expedição Qtd Produtos por Conferente */),
    component: (props) => <ReportMovementExpeditionCheckQtyProductsPerCheckerOutbound {...props} />,
  }, {
    path: ROUTES.REPORT_QTY_PRODUCTS_SEPARATED_PER_DATE_AND_USER,
    title: I18n.t('BEE2000' /* Qtd Produtos Separados por Data e Usuário */),
    component: (props) => <ReportMovementExpeditionCheckQtyProductsSeparatedPerDateAndUser {...props} />,
  }, {
    path: ROUTES.REPORT_PRODUCT_CURVE_DIVERGENCES,
    title: I18n.t('BEE1962' /* Divergências Curva dos Produtos */),
    component: (props) => <ReportProductCurveDivergences {...props} />,
  }, {
    path: ROUTES.REPORT_QTY_PRODUCTS_STORED_PER_USER,
    title: I18n.t('BEE1986' /* Qtd de Produtos Armazenados por Usuário */),
    component: (props) => <ReportQtyProductsStoredPerUser {...props} />,
  }, {
    path: ROUTES.REPORT_SALES_ORDER_STATUS,
    title: I18n.t('BEE2155' /* Status Pedido de Venda */),
    component: (props) => <ReportSalesOrderStatus {...props} />,
  }, {
    path: ROUTES.STOCK_WAVE_TRANSFER,
    title: I18n.t('BEE2294' /* Transferência por Onda */),
    component: (props) => <WaveTransferForm {...props} />,
  }, {
    path: ROUTES.STOCK_WAVE_TRANSFER_MONITOR,
    title: I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
    component: (props) => <WaveTransferMonitorList {...props} />,
  }, {
    path: ROUTES.STOCK_WAVE_TRANSFER_MONITOR_FORM,
    title: I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
    component: (props) => <WaveTransferMonitorForm {...props} />,
  }, {
    path: ROUTES.STOCK_WAVE_TRANSFER_MONITOR_FORM_DETAIL,
    title: I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
    component: (props) => <WaveTransferMonitorForm isDetail {...props} />,
  }, {
    path: ROUTES.STOCK_WAVE_TRANSFER_MONITOR_FORM_EDIT,
    title: I18n.t('BEE2295' /* Monitor de Transferência por Onda */),
    component: (props) => <WaveTransferMonitorForm isEdit {...props} />,
  },
  {
    path: ROUTES.MOVEMENT_RESUPPLY_RULES,
    title: I18n.t('BEE2665' /* Regras Ressuprimento Automático */),
    component: (props) => <ResupplyRulesList isEdit {...props} />,
  }, {
    path: ROUTES.MOVEMENT_RESUPPLY_RULES_CREATE,
    title: I18n.t('BEE2665' /* Regras Ressuprimento Automático */),
    component: (props) => <ResupplyRulesForm isCreate {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.MOVEMENT_RESUPPLY_RULES_DETAIL,
    title: I18n.t('BEE2665' /* Regras Ressuprimento Automático */),
    component: (props) => <ResupplyRulesForm isDetail {...props} />,
  }, {
    path: ROUTES.MOVEMENT_RESUPPLY_RULES_EDIT,
    title: I18n.t('BEE2665' /* Regras Ressuprimento Automático */),
    component: (props) => <ResupplyRulesForm isEdit {...props} />,
  }, {
    path: ROUTES.MOVEMENT_RESUPPLY_RULES_COPY,
    title: I18n.t('BEE2665' /* Regras Ressuprimento Automático */),
    component: (props) => <ResupplyRulesForm isCopy {...props} />,
  }, {
    path: ROUTES.MOVEMENT_RESUPPLY_GENERATE,
    title: I18n.t('BEE2666' /* Gerar Ressuprimento */),
    component: (props) => <GenerateResupplyList isEdit {...props} />,
  },
  {
    path: ROUTES.MOVEMENT_RESUPPLY_MONITOR,
    title: I18n.t('BEE2672' /* Monitor de Ressuprimento Automático */),
    component: (props) => <ResupplyRulesList isEdit {...props} />,
  }, {
    path: ROUTES.RESUPPLY_MONITOR,
    title: I18n.t('BEE2645' /* Monitor de Ressuprimento */),
    component: (props) => <ResupplyMonitorList {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.TRANSFER_BAPTISM,
    title: I18n.t('BEE1255' /* Gerar Batismo */),
    component: (props) => <BaptismForm type={3} {...props} />,
  }, {
    path: ROUTES.APPROVAL_LEVEL_LIST,
    title: I18n.t('BEE2044' /* Alçada de aprovação */),
    component: (props) => <ApprovalLevelList {...props} />,
  }, {
    path: ROUTES.APPROVAL_LEVEL_COPY,
    title: I18n.t('BEE2044' /* Alçada de aprovação */),
    component: (props) => <ApprovalLevelForm isCopy {...props} />,
  }, {
    path: ROUTES.APPROVAL_LEVEL_DETAIL,
    title: I18n.t('BEE2044' /* Alçada de aprovação */),
    component: (props) => <ApprovalLevelForm isDetail {...props} />,
  }, {
    path: ROUTES.APPROVAL_LEVEL_EDIT,
    title: I18n.t('BEE2044' /* Alçada de aprovação */),
    component: (props) => <ApprovalLevelForm isEdit {...props} />,
  }, {
    path: ROUTES.APPROVAL_LEVEL_CREATE,
    title: I18n.t('BEE2044' /* Alçada de aprovação */),
    component: (props) => <ApprovalLevelForm isCreate {...props} />,
  }, {
    path: ROUTES.MOVEMENT_INVENTORY_GENERATE,
    title: I18n.t('BEE1408' /* Gerar Inventário */),
    component: (props) => <GenerateInventoryList {...props} />,
  }, {
    path: ROUTES.MOVEMENT_INVENTORY_MAINTENANCE,
    title: I18n.t('BEE2105' /* Manutenção de Inventário */),
    component: (props) => <InventoryMaintenanceList {...props} />,
  }, {
    path: ROUTES.MOVEMENT_INVENTORY_MONITOR,
    title: I18n.t('BEE2136' /* Monitor de Inventário */),
    component: (props) => <InventoryMonitorList {...props} />,
  },
  {
    path: ROUTES.MOVEMENT_INVENTORY_MONITOR_SERIAL_LOG,
    title: I18n.t('BEE3615' /* Monitor de Logs - Inventário de Etiquetas */),
    component: (props) => <InventoryMonitorSerialLogList {...props} />,
  },
  {
    path: ROUTES.MOVEMENT_INVENTORY_COUNT_USER,
    title: I18n.t('BEE2138' /* Usuário de Contagem */),
    component: (props) => <CountUserList {...props} />,
  }, {
    path: ROUTES.UPDATE_INVENTORY_SHEET,
    title: I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
    component: (props) => <UpdateInventorySheet {...props} />,
  }, {
    path: ROUTES.REPORT_MOVEMENT_INVENTORY_COUNT,
    title: I18n.t('BEE2206' /* Contagem de Inventário */),
    component: (props) => <ReportInventoryCount {...props} />,
  }, {
    path: ROUTES.REPORT_MOVEMENT_INVENTORY_MONITOR_SERIAL_LOG,
    title: I18n.t('BEE3615' /* Monitor de Logs - Inventário de Etiquetas */),
    component: (props) => <ReportInventoryMonitorSheetSerialLog {...props} />,
  }, {
    path: ROUTES.PHYSICAL_INVENTORY,
    title: I18n.t('BEE2265' /* Inventario Físico */),
    component: (props) => <PhysicalInventory {...props} />,
  }, {
    path: ROUTES.INTEGRATION_MONITOR_BEESTOCK,
    title: I18n.t('BEE2925' /* Monitor de Integração BeeStock */),
    component: (props) => <IntegrationMonitorList isBeeStock {...props} />,
  }, {
    path: ROUTES.INTEGRATION_MONITOR_ERP,
    title: I18n.t('BEE2926' /* Monitor de Integração ERP */),
    component: (props) => <IntegrationMonitorList isBeeStock={false} {...props} />,
  }, {
    path: ROUTES.MOVEMENT_LABEL_LOT_WITH_STOCK_BALANCE,
    title: I18n.t('BEE2315' /* Etiqueta Lote com Saldo de Estoque */),
    component: (props) => <LabelLotWithStockBalance {...props} />,
  }, {
    path: ROUTES.CONSULT_STOCK_BALANCE_COMPARISON,
    title: I18n.t('BEE2328' /* Comparativo Saldo Stock */),
    component: (props) => <StockBalanceComparison {...props} />,
  }, {
    path: ROUTES.NOTIFICATION,
    title: I18n.t('BEE5' /* Notificações */),
    component: (props) => <Notification {...props} />,
  }, {
    path: ROUTES.NOTIFICATION_DETAIL,
    title: I18n.t('BEE5' /* Notificações */),
    component: (props) => <NotificationDetail {...props} />,
  }, {
    path: ROUTES.CAPACITY_LIST,
    title: I18n.t('BEE389' /* Capacidade */),
    component: (props) => <CapacityList {...props} />,
  }, {
    path: ROUTES.CAPACITY_DETAIL,
    title: I18n.t('BEE389' /* Capacidade */),
    component: (props) => <CapacityForm mode="detail" {...props} />,
  }, {
    path: ROUTES.CAPACITY_CREATE,
    title: I18n.t('BEE389' /* Capacidade */),
    component: (props) => <CapacityForm mode="create" {...props} />,
  }, {
    path: ROUTES.CAPACITY_EDIT,
    title: I18n.t('BEE389' /* Capacidade */),
    component: (props) => <CapacityForm mode="edit" {...props} />,
  }, {
    path: ROUTES.CAPACITY_COPY,
    title: I18n.t('BEE389' /* Capacidade */),
    component: (props) => <CapacityForm mode="copy" {...props} />,
  }, {
    path: ROUTES.PICKING_AREA_LIST,
    title: I18n.t('BEE2570' /* Áreas de Picking */),
    component: (props) => <PickingAreaList {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.PICKING_AREA_DETAIL,
    title: I18n.t('BEE2570' /* Áreas de Picking */),
    component: (props) => <PickingAreaForm mode="detail" {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.PICKING_AREA_CREATE,
    title: I18n.t('BEE2570' /* Áreas de Picking */),
    component: (props) => <PickingAreaForm mode="create" {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.PICKING_AREA_EDIT,
    title: I18n.t('BEE2570' /* Áreas de Picking */),
    component: (props) => <PickingAreaForm mode="edit" {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.PICKING_AREA_COPY,
    title: I18n.t('BEE2570' /* Áreas de Picking */),
    component: (props) => <PickingAreaForm mode="copy" {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.PICKING_AREA_LINK_ADDRESS,
    title: I18n.t('BEE2570' /* Áreas de Picking */),
    component: (props) => <PickingAreaLinkAddress {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.INDUSTRY_PRODUCTION_ORDER,
    title: I18n.t('BEE2770' /* Ordem de Produção */),
    component: (props) => <ProductionOrderList {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.INDUSTRY_GENERATE_LABEL_SERIAL,
    title: I18n.t('BEE2784' /* Gerar Etiquetas Seriadas */),
    component: (props) => <GenerateSerialLabel {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.INDUSTRY_MANUAL_REQUEST,
    title: I18n.t('BEE2842' /* Gerar Requisição Manual */),
    component: (props) => <GenerateManualRequest {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.INDUSTRY_LABEL_SERIAL,
    title: I18n.t('BEE2760' /* Etiquetas Seriadas */),
    component: (props) => <LabelSerialList {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  },
  {
    path: ROUTES.INDUSTRY_PRINT_SERIAL_GROUP,
    title: I18n.t('BEE3255' /* Impressão Etiquetas Agrupadoras */),
    component: (props) => <PrintSerialGroup {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.WIZARD_SETUP,
    title: I18n.t('BEE2877' /* Configurações inciais */),
    component: (props) => <Wizard {...props} />,
    privated: false,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.RESUPPLY_DEPOSITS_LIST,
    title: I18n.t('BEE2932' /* Associação de Depósitos */),
    component: (props) => <ResupplyDepositsList {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.RESUPPLY_DEPOSITS_DETAIL,
    title: I18n.t('BEE2932' /* Associação de Depósitos */),
    component: (props) => <ResupplyDepositsForm mode="detail" {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.RESUPPLY_DEPOSITS_CREATE,
    title: I18n.t('BEE2932' /* Associação de Depósitos */),
    component: (props) => <ResupplyDepositsForm mode="create" {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.RESUPPLY_DEPOSITS_EDIT,
    title: I18n.t('BEE2932' /* Associação de Depósitos */),
    component: (props) => <ResupplyDepositsForm mode="edit" {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.RESUPPLY_DEPOSITS_COPY,
    title: I18n.t('BEE2932' /* Associação de Depósitos */),
    component: (props) => <ResupplyDepositsForm mode="copy" {...props} />,
    animEffect: { type: 'slidecustom', timeout: 300 },
  }, {
    path: ROUTES.OUTBOUND_CHANGE_ALLOCATION,
    title: I18n.t('BEE3296' /* Alterar Alocação */),
    component: (props) => <ChangeProductAllocation {...props} />,
  }, {
    path: '*',
    title: I18n.t('BEE526' /* Página não encontrada... */),
    component: (props) => <PageNotFound {...props} />,
    privated: false,
  },
];

export default routes;
