import RestService from '../services/rest';

// POST
async function printEanLabel(eanCode, printerCode, filters, amount) {
  return RestService.postAuthenticated('labels/print/ean', {
    eanCode, printerCode, filters, amount,
  });
}

async function printLotLabels(lots, printerCode) {
  return RestService.postAuthenticated('labels/print/lot', { lots, printerCode });
}

async function printPreConferenceLabel(preConferenceLabelId, printerCode) {
  return RestService.postAuthenticated('labels/print/preConference', { preConferenceLabelId, printerCode });
}

async function printConsolidationLabel(printerCode, outboundOrderId, customerCode) {
  return RestService.postAuthenticated('labels/print/consolidations', { printerCode, outboundOrderId, customerCode });
}

async function printBaptismLabel(printerCode, baptismStart, baptismEnd, branchCode, typeBaptism) {
  return RestService.postAuthenticated('labels/print/baptism', {
    printerCode, baptismStart, baptismEnd, branchCode, typeBaptism,
  });
}

async function printConferenceVolumeLabel(printerCode, checkedVolumeIds) {
  return RestService.postAuthenticated('labels/print/conferenceVolume', { printerCode, checkedVolumeIds });
}

async function printKardexCableCutLabel(printerCode, productCode, addressCode, lotCode) {
  return RestService.postAuthenticated('labels/print/KardexCableCut', {
    printerCode, productCode, addressCode, lotCode,
  });
}

async function printAddressLabel(branchCode, printerCode, filters, columnSide, printOrder) {
  return RestService.postAuthenticated('labels/print/address', {
    branchCode, printerCode, filters, columnSide, printOrder,
  });
}

async function printLotWithStockBalance(printerCode, filters, printOrder, columnSide) {
  return RestService.postAuthenticated('labels/print/lotWithStockBalance', {
    printerCode, filters, printOrder, columnSide,
  });
}

async function printSerialGroup(printerCode, serialGroupId) {
  return RestService.postAuthenticated('labels/print/serialGroup', {
    printerCode, serialGroupId,
  });
}

async function reprintSerialGroup(printerCode, serialGroupId) {
  return RestService.postAuthenticated('labels/reprint/serialGroup', {
    printerCode, serialGroupId,
  });
}

async function printConsolidationsLabel(printerCode, outboundOrderId) {
  return RestService.postAuthenticated('labels/print/consolidations', { printerCode, outboundOrderId });
}

export default {
  printEanLabel,
  printLotLabels,
  printPreConferenceLabel,
  printConsolidationLabel,
  printBaptismLabel,
  printConferenceVolumeLabel,
  printKardexCableCutLabel,
  printAddressLabel,
  printLotWithStockBalance,
  printSerialGroup,
  printConsolidationsLabel,
  reprintSerialGroup,
};
