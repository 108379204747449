/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import Form from '../../../components/form/form';
import UserConfirmationModal from '../../../components/pages/userConfirmationModal';

import {
  getPendingConfirmDelivery,
  confirmDelivery,
} from '../../../app/store/actions/outboundOrders';

class ConfirmDeliveryList extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value:
          (
            <>
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </>),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE3680' /* Confirmar Entrega */), active: true },
    ];

    this.state = {
      checked: {},
      listPendingDocuments: [],
      showConfirm: false,
      defaultPageSize: 10,
      defaultSorted: [{ id: 'orderNumber', desc: false }],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      withdrawnBy: '',
      userDocument: '',
      note: '',
      modalConfirmUser: false,
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE436' /* Selecionar */),
        accessor: 'action',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={this.state.checked[row.original.outboundOrderId] || false}
              onChange={() => this.singleSelection(row.original)}
            />
          </div>
        ),
      }, {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 140,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <OneClickButton
              type="button"
              className="btn btn-120 btn-primary p-1 m-1"
              onClick={async () => { await this.handleConfirmDelivery(row.original.outboundOrderId); }}
            >
              {I18n.t('BEE3680' /* Confirmar Entrega */)}
            </OneClickButton>
          </div>
        ),
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customer',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE51' /* Pedido */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE402' /* Nota Fiscal */),
        accessor: 'invoiceNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      },
    ];
  }

  async componentDidMount() {
    await this.getPendingConfirmDelivery();
  }

  singleSelection = (selection) => {
    const { checked } = this.state;
    checked[selection.outboundOrderId] = !checked[selection.outboundOrderId];
    this.setState({ checked: { ...checked } });
  };

  multipleSelection = (selectionOption) => {
    const { checked } = this.state;
    const data = this.reactTable.getResolvedState().sortedData;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index += 1) {
        const element = data[index]._original;
        checked[element.outboundOrderId] = selectionOption;
      }
      this.setState({ checked: { ...checked } });
    }
  };

  setValue = (attr, value) => {
    this.setState({
      [attr]: value,
    });
  };

  getPendingConfirmDelivery = async () => {
    try {
      const { startDate, endDate } = this.state;
      const listPendingDocuments = await this.props.getPendingConfirmDelivery(
        '',
        moment(startDate).format(),
        moment(endDate).format(),
      );
      if (listPendingDocuments) {
        this.setState({ listPendingDocuments });
      }
    } catch (error) {
      const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE3680' /* Confirmar Entrega */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE3680' /* Confirmar Entrega */),
          I18n.t('BEE1918' /* Não foi possível localizar os documentos */),
          'top-right',
        );
      }
    }
  };

  submitConfirmDelivery = async () => {
    const {
      userDocument, withdrawnBy, note, checked,
    } = this.state;

    const checkedSheetIds = [];
    Object.keys(checked).forEach((index) => {
      if (checked[index]) checkedSheetIds.push(index);
    });
    try {
      const orders = await this.props.confirmDelivery(
        checkedSheetIds,
        userDocument,
        withdrawnBy,
        note,
      );

      if (orders && !orders.success) {
        addNotification(
          'danger',
          I18n.t('BEE3680' /* Confirmar Entrega */),
          I18n.t('BEE1630' /* Ocorreu uma falha interna no servidor, verifique a conexão! */),
          'top-right',
        );
        return;
      }

      addNotification(
        'success',
        I18n.t('BEE3680' /* Confirmar Entrega */),
        I18n.t('BEE3730' /* Entrega confirmada com sucesso! */),
        'top-right',
      );

      await this.setState({
        checked: {},
        listPendingDocuments: [],
        showConfirm: false,
        withdrawnBy: '',
        userDocument: '',
        note: '',
      });

      await this.getPendingConfirmDelivery();
    } catch (error) {
      const responseError = error.response;
      if (responseError && responseError.data && responseError.data.error) {
        addNotification(
          'danger',
          I18n.t('BEE3680' /* Confirmar Entrega */),
          responseError.data.error.message,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1498' /* Aviso */),
          I18n.t('BEE1630' /* Ocorreu uma falha interna no servidor, verifique a conexão! */),
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });

    await this.getPendingConfirmDelivery();
  };

  handleConfirmDelivery = async (outboundOrderId) => {
    const { checked } = this.state;

    if (outboundOrderId) {
      checked[outboundOrderId] = true;
    }

    await this.setState({ showConfirm: true, checked: { ...checked } });

    document.getElementById(1).focus();
    document.getElementById(1).select();
  };

  handleCancelConfirmDelivery = async () => {
    await this.setState({ showConfirm: false, checked: {} });
  };

  goToElement = (e, id) => {
    if (e.keyCode === 13) {
      document.getElementById(id + 1).focus();
      document.getElementById(id + 1).select();
    }
  };

  confirmDeliveryForm = async () => {
    const {
      withdrawnBy, checked,
    } = this.state;

    const checkedSheetIds = [];
    Object.keys(checked).forEach((index) => {
      if (checked[index]) checkedSheetIds.push(index);
    });

    if (!checkedSheetIds || checkedSheetIds.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE3680' /* Confirmar Entrega */),
        I18n.t('BEE1915' /* Documento não localizada */),
        'top-right',
      );

      return;
    }

    if (!withdrawnBy) {
      addNotification(
        'danger',
        I18n.t('BEE3680' /* Confirmar Entrega */),
        I18n.t('BEE3731' /* Informe ao usuário que retirou a mercadoria! */),
        'top-right',
      );

      document.getElementById(1).focus();
      document.getElementById(1).select();

      return;
    }

    await this.setState({ modalConfirmUser: true });
  };

  userConfirmation = async (confirm) => {
    await this.setState({ modalConfirmUser: false });
    if (confirm) {
      await this.submitConfirmDelivery();
    }
  };

  render() {
    const {
      showConfirm,
      defaultPageSize,
      defaultSorted,
      listPendingDocuments,
      withdrawnBy,
      userDocument,
      note,
      modalConfirmUser,
    } = this.state;

    const { checked } = this.state;
    const checkedSheetIds = [];
    Object.keys(checked).forEach((index) => {
      if (checked[index]) checkedSheetIds.push(index);
    });
    let message = '';

    if (checkedSheetIds.length > 1) {
      message = I18n.t(
        'BEE3741',
        { 0: checkedSheetIds.length }, /* A entrega de %{0} documentos será confirmada, deseja continuar? */
      );
    } else if (checkedSheetIds.length === 1) {
      message = I18n.t('BEE3742', {
        0: listPendingDocuments.find((item) => item.outboundOrderId === Number(checkedSheetIds[0])).orderNumber,
      } /* A entrega do documento %{0} será confirmada. Deseja continuar? */);
    }

    return (
      <>
        <Table
          wikiHelp={ROUTES.OUTBOUND_CONFIRM_DELIVERY_HELP}
          ref={(r) => {
            this.reactTable = r && r.reactTable;
          }}
          downloadCSV
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE3680' /* Confirmar Entrega */)}
          downButtonPosition
          panelHeaderProps={{
            onClickReload: this.getPendingConfirmDelivery,
          }}
          actionButtons={(
            <div className="ml-auto">
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(true)}
              >
                {I18n.t('BEE1908' /* Marcar Todos */)}
              </button>
              <button
                type="button"
                className="btn btn-120 btn-secondary p-5 m-5"
                onClick={() => this.multipleSelection(false)}
              >
                {I18n.t('BEE1909' /* Desmarcar Todos */)}
              </button>
              <OneClickButton
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={this.handleConfirmDelivery}
              >
                {I18n.t('BEE3680' /* Confirmar Entrega */)}
              </OneClickButton>
            </div>
          )}
          filterable
          data={listPendingDocuments}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        <Modal isOpen={showConfirm} toggle={this.handleCancelConfirmDelivery}>
          <ModalHeader toggle={this.handleCancelConfirmDelivery}>
            {I18n.t('BEE3680' /* Confirmar Entrega */)}
          </ModalHeader>
          <ModalBody>
            <Form
              noHeader
              headerProps={{
                className: 'w-100',
              }}
              setValue={this.setValue}
              footerContent={(
                <div className="text-center p-3">
                  <span className="p-2" style={{ fontSize: 14 }}>{message}</span>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      className="btn btn-120 btn-white p-5 m-5"
                      onClick={this.handleCancelConfirmDelivery}
                    >
                      {I18n.t('BEE99' /* Cancelar */)}
                    </button>
                    <button
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={this.confirmDeliveryForm}
                    >
                      {I18n.t('BEE1849' /* Continuar */)}
                    </button>
                  </div>
                </div>
              )}
              inputs={(formContext) => ([
                formContext.createInput(
                  withdrawnBy,
                  'withdrawnBy',
                  I18n.t('BEE3690' /* Retirado por */),
                  '',
                  'text',
                  true,
                  undefined,
                  undefined,
                  6,
                  this.goToElement,
                  1,
                  undefined,
                  ['form-group row m-b-15', 'col-form-label col-md-4'],
                ),
                formContext.createInput(
                  userDocument,
                  'userDocument',
                  I18n.t('BEE3691' /* Documento do Recebedor */),
                  '',
                  'text',
                  true,
                  undefined,
                  undefined,
                  6,
                  this.goToElement,
                  2,
                  undefined,
                  ['form-group row m-b-15', 'col-form-label col-md-4'],
                ),
                formContext.createTextArea(
                  note,
                  'note',
                  I18n.t('BEE135' /* Observação */),
                  '',
                  5,
                  undefined,
                  undefined,
                  undefined,
                  ['form-group row m-b-15', 'col-form-label col-md-4', 'col-md-6'],
                  3,
                ),
              ])}
            />
          </ModalBody>
        </Modal>
        <UserConfirmationModal
          openModal={modalConfirmUser}
          confirm={this.userConfirmation}
        />
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getPendingConfirmDelivery: (orderNumber, filterStartDate, filterEndDate) => dispatch(getPendingConfirmDelivery(
    orderNumber,
    filterStartDate,
    filterEndDate,
  )),
  confirmDelivery: (outboundOrderId, userDocument, withdrawnBy, note) => dispatch(
    confirmDelivery(outboundOrderId, userDocument, withdrawnBy, note),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmDeliveryList));
