/* eslint-disable class-methods-use-this */
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Chart, Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
  Card, CardBody, CardTitle, Container, Row, Col,
} from 'reactstrap';
import addNotification from '../../../components/notification';
import { getBranchesOptions } from '../../../app/store/actions/branches';
import { Panel, PanelBody, PanelHeader } from '../../../components/panel/panel';
// 😁
import { getStockDashboardInfo } from '../../../app/store/actions/stocks';
import BarChart from '../../../components/charts/BarChart';

import FilterSelectionList from '../../../components/form/filterSelectionList';

import { getDepositsOptions } from '../../../app/store/actions/deposits';

class AddressOccupationDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      occupiedAddresses: 0,
      freeAddresses: 0,
      totalAddresses: 0,
      selectedBranch: '',
      charts: {},
      speedometer: {},
    };

    this.breadcrumb = [
      { value: I18n.t('BEE42' /* Dashboard */), iconClassName: 'fa fa-chart-line m-t-10 m-r-5' },
      { value: I18n.t('BEE32' /* Estoque */) },
      { value: I18n.t('BEE2458' /* Ocupação de Endereços */), active: true },
    ];
  }

  async componentDidMount() {
    const listBranches = await this.props.getBranchesOptions();
    const listDeposits = await this.props.getDepositsOptions();

    this.setState({
      listDeposits,
      listBranches,
    });
  }

  handleDepositsSelected = async (event) => {
    const selectedDeposits = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const element of event) {
      selectedDeposits.push({
        value: element.value,
        label: element.label,
      });
    }

    this.setState({
      selectedDeposits: selectedDeposits || {},
    });
  };

  handleBranchesSelected = async (event) => {
    const selectedBranches = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const element of event) {
      selectedBranches.push({
        value: element.value,
        label: element.label,
      });
    }

    this.setState({
      selectedBranch: selectedBranches || {},
    });
  };

  handleConfirmButton = async () => {
    const { selectedBranch } = this.state;

    if ((Array.isArray(selectedBranch) && selectedBranch.length === 0) || !selectedBranch) {
      await addNotification(
        'danger',
        I18n.t('BEE2386' /* Verifique os Filtros */),
        I18n.t('BEE1005' /* É necessário informar a filial */),
        'top-right',
      );
      return;
    }

    this.handleStockDashboardSearch();
  };

  handleColorAccuracityPercentage = (value) => {
    if (value <= 50) {
      return '#008a37';
    }
    if (value <= 65) {
      return '#F1CD62';
    }
    if (value <= 90) {
      return '#ffc000';
    }
    return '#fd4949';
  };

  handleStockDashboardSearch = async () => {
    const {
      selectedBranch, selectedDeposits,
    } = this.state;

    const branchesToSearch = [];
    if (selectedBranch) {
      selectedBranch.forEach((select) => { branchesToSearch.push(select.value); });
    }

    const depositsToSearch = [];
    if (selectedDeposits) {
      selectedDeposits.forEach((select) => { depositsToSearch.push(select.value); });
    }

    const dashboardInfo = await this.props.getStockDashboardInfo(branchesToSearch, depositsToSearch);

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    const productsValuesSum = formatter.format(dashboardInfo.productsValuesSum);

    await this.setState({
      charts: dashboardInfo.charts,
      occupiedAddresses: dashboardInfo.occupiedAddresses,
      freeAddresses: dashboardInfo.freeAddresses,
      totalAddresses: dashboardInfo.totalAddresses,
      productsSKU: dashboardInfo.productsSKU,
      productsValuesSum,
      speedometer: {
        labels: ['Baixo', 'Médio', 'Alto', 'Crítico'],
        datasets: [
          {
            data: [50, 15, 25, 10],
            needleValue: dashboardInfo.charts.speedometerNeedleValue,
            backgroundColor: ['#a8d18d', '#ffe699', '#ffc000', '#cd3301'],
            hoverBackgroundColor: ['#008a37', '#F1CD62', '#ffd654', '#fd4949'],
          },
        ],
        options: {
          tooltips: { enabled: false },
          plugins: {
            datalabels: {
              formatter(value, context) {
                return context.chart.config.data.labels[context.dataIndex];
              },
              display: true,
              borderRadius: 3,
              color: 'white',
              font: {
                size: 16,
              },
            },
          },
          layout: {
            padding: {
              bottom: 3,
            },
          },
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          legend: {
            display: false,
          },
          cutoutPercentage: 50,
        },
      },
    });

    Chart.pluginService.register({
      afterDraw: (chart) => {
        const { needleValue } = chart.chart.config.data.datasets[0];
        const dataTotal = chart.chart.config.data.datasets[0].data.reduce(
          (a, b) => a + b,
          0,
        );
        const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
        const { ctx } = chart.chart;
        const cw = chart.chart.canvas.offsetWidth;
        const ch = chart.chart.canvas.offsetHeight;
        const cx = cw / 2;
        const cy = ch - 5;
        ctx.translate(cx, cy);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(0, -5);
        ctx.lineTo(ch - 30, 0);
        ctx.lineTo(0, 5);
        ctx.fillStyle = 'rgb(0, 0, 0)';
        ctx.fill();
        ctx.rotate(-angle);
        ctx.translate(-cx, -cy);
        ctx.beginPath();
        ctx.arc(cx, cy, 5, 0, Math.PI * 2);
        ctx.fill();

        const { width } = chart.chart;
        const { height } = chart.chart;
        ctx.restore();
        ctx.font = 'normal small-caps 1.7rem bold sans-serif';
        ctx.textBaseline = 'bottom';
        ctx.fillStyle = this.handleColorAccuracityPercentage(needleValue);
        const text = `${needleValue}%`;
        const textX = Math.round((width - ctx.measureText(text).width) / 1.95);
        const textY = height / 1.4;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      occupiedAddresses,
      freeAddresses,
      totalAddresses,
      productsSKU,
      productsValuesSum,
      charts,
      listDeposits,
      listBranches,
    } = this.state;
    return (
      <div>
        {!!this.breadcrumb && this.breadcrumb.length && (
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            {this.breadcrumb.map((it, index) => (
              <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                {
                  it.iconClassName
                    ? (
                      <>
                        <i className="fa fa-chart-line m-t-10 m-r-5" />
                        {' '}
                        {it.value}
                      </>
                    ) : (
                      it.value
                    )
                }
              </li>
            ))}
          </ol>
        </div>
        )}
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2460' /* Dashboard Estoque */)}
          </h1>
        </div>
        <div className="row mt-2 mx-0 p-0 mb-3">
          {/* Filiais */}
          <div className="d-flex mt-1 ml-1">
            <FilterSelectionList
              value={this.props.selectedBranch}
              onChangeValue={this.handleBranchesSelected}
              placeholderValue={I18n.t('BEE2433' /* Nenhuma filial selecionada */)}
              optionsValue={listBranches}
              onClickValue={this.handleConfirmButton}
            />
          </div>
          {/* Depositos */}
          <div className="d-flex mt-1 ml-1">
            <FilterSelectionList
              value={this.props.selectedDeposits}
              onChangeValue={this.handleDepositsSelected}
              placeholderValue={I18n.t('BEE3753' /* Todos os depósitos foram selecionados */)}
              optionsValue={listDeposits}
              onClickValue={this.handleConfirmButton}
            />
          </div>
        </div>

        <Panel className="panel-with-tabs">
          <PanelHeader noButton noHeader />
          <PanelBody>
            <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '1' ? 'active' : ''}
                  onClick={() => {
                    this.toggleTab('1');
                  }}
                >
                  <span className="d-sm-none">Tab 1</span>
                  <span className="d-sm-block d-none">{I18n.t('BEE3622' /* Ocupação */)}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '2' ? 'active' : ''}
                  onClick={() => {
                    this.toggleTab('2');
                  }}
                >
                  <span className="d-sm-none">Tab 2</span>
                  <span className="d-sm-block d-none">{I18n.t('BEE3599' /* Valores */)}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '3' ? 'active' : ''}
                  onClick={() => {
                    this.toggleTab('3');
                  }}
                >
                  <span className="d-sm-none">Tab 3</span>
                  <span className="d-sm-block d-none">{I18n.t('BEE2543' /* Unidades */)}</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="row">
                  <div className="col-xl-12">

                    {/* Crie os inputs aqui */}

                    <Panel>
                      <PanelHeader onClickCollapse onClickReload={this.handleConfirmButton}>
                        {I18n.t('BEE2461' /* Dashboard Ocupação de Endereços */)}
                      </PanelHeader>
                      <PanelBody>
                        <div>
                          { totalAddresses ? (
                            <div className="d-lg-flex flex-row w-100 h-100">
                              <div className="w-100 mt-1 mr-2 rounded" style={{ height: '380px', border: 'solid #2d353c 1px' }}>
                                <div className="d-flex flex-column mt-3">
                                  <div style={{ flex: 'none' }}>
                                    <div className="d-flex justify-content-center">
                                      <h2>{I18n.t('BEE2485' /* Ocupação Geral do Estoque */)}</h2>
                                    </div>
                                    <div className="h-100">
                                      {this.state.speedometer
                                    && (
                                      <Doughnut
                                        height={100}
                                        data={this.state.speedometer}
                                        plugins={[ChartDataLabels]}
                                        options={this.state.speedometer.options}
                                      />
                                    )}
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-center" style={{ flex: 'none' }}>
                                    <table className="text-black text-center border">
                                      <thead>
                                        <tr className="border font-weight-bold bg-dark text-white" style={{ height: '35px' }}>
                                          <th>{I18n.t('BEE283' /* Nível */)}</th>
                                          <th>{I18n.t('BEE2437' /* Faixa */)}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className="border" style={{ height: '25px' }}>
                                          <td className="pr-3 pl-3">{I18n.t('BEE611' /* Baixo */)}</td>
                                          <td className="pr-3 pl-3">{I18n.t('BEE2444', { 0: '50%' } /* Até %{0} */)}</td>
                                        </tr>
                                        <tr className="border" style={{ height: '25px' }}>
                                          <td className="pr-3 pl-3">{I18n.t('BEE391' /* Médio */)}</td>
                                          <td className="pr-3 pl-3">
                                            {I18n.t('BEE2443', { 0: '50%', 1: '65%' } /* %{0} a %{1} */)}

                                          </td>
                                        </tr>
                                        <tr className="border" style={{ height: '25px' }}>
                                          <td className="pr-3 pl-3">{I18n.t('BEE2445' /* Alto */)}</td>
                                          <td className="pr-3 pl-3">
                                            {I18n.t('BEE2443', { 0: '65%', 1: '90%' } /* %{0} a %{1} */)}

                                          </td>
                                        </tr>
                                        <tr className="border" style={{ height: '25px' }}>
                                          <td className="pr-3 pl-3">{I18n.t('BEE2438' /* Crítico */)}</td>
                                          <td className="pr-3 pl-3">
                                            {I18n.t('BEE2443', { 0: '90%', 1: '100%' } /* %{0} a %{1} */)}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="w-100 rounded mr-2 mt-1"
                                style={{
                                  height: '380px',
                                  border: 'solid #2d353c 1px',
                                }}
                              >
                                <div className="h-100 w-100 d-flex flex-column">
                                  <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center ">
                                    <span style={{ fontSize: '3rem' }}>{totalAddresses}</span>
                                    <span style={{
                                      fontSize: '1.2rem',
                                    }}
                                    >
                                      {I18n.t('BEE2486' /* Total de Endereços */)}

                                    </span>
                                  </div>
                                  <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                                    <span style={{ fontSize: '3rem' }}>{occupiedAddresses}</span>
                                    <span style={{
                                      fontSize: '1.2rem',
                                    }}
                                    >
                                      {I18n.t('BEE2487' /* Ocupados */)}

                                    </span>
                                  </div>
                                  <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                                    <span style={{ fontSize: '3rem' }}>{freeAddresses}</span>
                                    <span style={{
                                      fontSize: '1.2rem',
                                    }}
                                    >
                                      {I18n.t('BEE2488' /* Disponíveis */)}

                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="w-100 rounded mt-1"
                                style={{
                                  height: '380px',
                                  border: 'solid #2d353c 1px',
                                }}
                              >
                                <div className="h-100 w-100 d-flex flex-column">
                                  <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center ">
                                    <span style={{ fontSize: '3rem' }}>{productsValuesSum}</span>
                                    <span style={{
                                      fontSize: '1.2rem',
                                    }}
                                    >
                                      {I18n.t('BEE2489' /* Valor Total em Estoque */)}

                                    </span>
                                  </div>
                                  <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                                    <span style={{ fontSize: '3rem' }}>{productsSKU}</span>
                                    <span style={{
                                      fontSize: '1.2rem',
                                    }}
                                    >
                                      {I18n.t('BEE2490' /* SKU's em Estoque */)}

                                    </span>
                                  </div>
                                  <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center" />
                                </div>
                              </div>
                            </div>
                          )
                            : (
                              <div className="d-flex flex-column w-100" style={{ height: '380px' }}>
                                <div
                                  className="d-flex align-items-center justify-content-center user-select-none"
                                  style={{
                                    flex: 'auto', color: '#9b8a79', fontSize: '1rem', fontFamily: 'Helvetica',
                                  }}
                                >
                                  {I18n.t('BEE2423' /* Não há dados no filtro informado */)}
                                </div>
                              </div>
                            )}
                        </div>
                      </PanelBody>
                    </Panel>
                    <BarChart
                      barChartValues={charts.sectorStockOccupancyChartInfo}
                      height={350}
                      useFormatter
                      title={I18n.t('BEE2459' /* Ocupação por Setor */)}
                      onClickReload={this.handleConfirmButton}
                      isVerticalBar
                    />
                    <div className="row mt-4">
                      <div className="col-xl-6">
                        <BarChart
                          barChartValues={charts.occupationByAddressClassificationInfo}
                          height={350}
                          useFormatter
                          title={I18n.t('BEE2462' /* Ocupação por Classificação */)}
                          onClickReload={this.handleConfirmButton}
                          isVerticalBar
                        />
                      </div>
                      <div className="col-xl-6">
                        <BarChart
                          barChartValues={charts.occupationByAddressTypeChartInfo}
                          height={350}
                          useFormatter
                          title={I18n.t('BEE2483' /* Ocupação por Tipo de Endereço */)}
                          onClickReload={this.handleConfirmButton}
                          isVerticalBar
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="row">
                  <div className="col-xl-12">
                    {/* Crie os inputs aqui */}
                    <div className="row mt-4">
                      <div className="col-xl-6">
                        <BarChart
                          barChartValues={charts.valueByStockChartInfo}
                          height={350}
                          useFormatter
                          formatToCurrency
                          isVerticalBar
                          title={I18n.t('BEE3623' /* Valor de Depósito */)}
                          onClickReload={this.handleConfirmButton}
                        />
                        {charts.valueByStockChartInfo && charts.valueByStockChartInfo.totalSum !== undefined && (
                        <Container className="d-flex justify-content-center mt-4">
                          <span className="font-weight-bold">
                            {`${I18n.t('BEE3757' /* Total Geral */)}: ${charts.valueByStockChartInfo.totalSum.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                          </span>
                        </Container>
                        )}
                      </div>
                      <div className="col-xl-6">
                        <BarChart
                          barChartValues={charts.valueByFamilyChartInfo}
                          height={350}
                          useFormatter
                          formatToCurrency
                          isVerticalBar
                          title={I18n.t('BEE3624' /* Valor por Família */)}
                          onClickReload={this.handleConfirmButton}
                        />
                        {charts.valueByFamilyChartInfo && charts.valueByFamilyChartInfo.totalSum !== undefined && (
                        <Container className="d-flex justify-content-center mt-4">
                          <span className="font-weight-bold">
                            {`${I18n.t('BEE3757' /* Total Geral */)}: ${charts.valueByFamilyChartInfo.totalSum.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                          </span>
                        </Container>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    {/* Crie os inputs aqui */}
                    <div className="row mt-4">
                      <div className="col-xl-6">
                        <BarChart
                          barChartValues={charts.valueBySectorChartInfo}
                          height={350}
                          useFormatter
                          formatToCurrency
                          isVerticalBar
                          title={I18n.t('BEE3625' /* Valor por setor */)}
                          onClickReload={this.handleConfirmButton}
                        />
                        {charts.valueByFamilyChartInfo && charts.valueByFamilyChartInfo.totalSum !== undefined && (
                        <Container className="d-flex justify-content-center mt-4">
                          <span className="font-weight-bold">
                            {`${I18n.t('BEE3757' /* Total Geral */)}: ${charts.valueBySectorChartInfo.totalSum.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                          </span>
                        </Container>
                        )}
                      </div>
                      <div className="col-xl-6">
                        <BarChart
                          barChartValues={charts.valueByBranchChartInfo}
                          height={350}
                          useFormatter
                          formatToCurrency
                          isVerticalBar
                          title={I18n.t('BEE3626' /* Valor por Filial */)}
                          onClickReload={this.handleConfirmButton}
                        />
                        {charts.valueByFamilyChartInfo && charts.valueByFamilyChartInfo.totalSum !== undefined && (
                        <Container className="d-flex justify-content-center mt-4">
                          <span className="font-weight-bold">
                            {`${I18n.t('BEE3757' /* Total Geral */)}: ${charts.valueByBranchChartInfo.totalSum.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                          </span>
                        </Container>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="row">
                  <div className="col-xl-12">
                    {/* Crie os inputs aqui */}
                    <div className="row mt-4">
                      <div className="col-xl-6">
                        <BarChart
                          barChartValues={charts.unitsByStockChartInfo}
                          height={350}
                          useFormatter
                          formatToQuantity
                          title={I18n.t('BEE3638' /* Unidades por Depósito */)}
                          onClickReload={this.handleConfirmButton}
                        />
                        {charts.unitsByStockChartInfo && charts.unitsByStockChartInfo.totalSum !== undefined && (
                        <Container className="d-flex justify-content-center mt-4">
                          <span className="font-weight-bold">
                            {`${I18n.t('BEE3757' /* Total Geral */)}: ${charts.unitsByStockChartInfo.totalSum.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                          </span>
                        </Container>
                        )}
                      </div>
                      <div className="col-xl-6">
                        <BarChart
                          barChartValues={charts.unitsByFamilyChartInfo}
                          height={350}
                          useFormatter
                          formatToQuantity
                          title={I18n.t('BEE3639' /* Unidades por Família */)}
                          onClickReload={this.handleConfirmButton}
                        />
                        {charts.unitsByFamilyChartInfo && charts.unitsByFamilyChartInfo.totalSum !== undefined && (
                        <Container className="d-flex justify-content-center mt-4">
                          <span className="font-weight-bold">
                            {`${I18n.t('BEE3757' /* Total Geral */)}: ${charts.unitsByFamilyChartInfo.totalSum.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                          </span>
                        </Container>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    {/* Crie os inputs aqui */}
                    <div className="row mt-4">
                      <div className="col-xl-6">
                        <BarChart
                          barChartValues={charts.unitsBySectorChartInfo}
                          height={350}
                          useFormatter
                          formatToQuantity
                          title={I18n.t('BEE3640' /* Unidades por Setor */)}
                          onClickReload={this.handleConfirmButton}
                        />
                        {charts.unitsBySectorChartInfo && charts.unitsBySectorChartInfo.totalSum !== undefined && (
                        <Container className="d-flex justify-content-center mt-4">
                          <span className="font-weight-bold">
                            {`${I18n.t('BEE3757' /* Total Geral */)}: ${charts.unitsBySectorChartInfo.totalSum.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                          </span>
                        </Container>
                        )}
                      </div>
                      <div className="col-xl-6">
                        <BarChart
                          barChartValues={charts.unitsByBranchChartInfo}
                          height={350}
                          useFormatter
                          formatToQuantity
                          title={I18n.t('BEE3641' /* Unidades por Filial */)}
                          onClickReload={this.handleConfirmButton}
                        />
                        {charts.unitsByBranchChartInfo && charts.unitsByBranchChartInfo.totalSum !== undefined && (
                        <Container className="d-flex justify-content-center mt-4">
                          <span className="font-weight-bold">
                            {`${I18n.t('BEE3757' /* Total Geral */)}: ${charts.unitsByBranchChartInfo.totalSum.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                          </span>
                        </Container>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getDepositsOptions: () => dispatch(getDepositsOptions()),
  getStockDashboardInfo: (
    selectedBranch,
    selectedDeposits,
  ) => dispatch(getStockDashboardInfo(selectedBranch, selectedDeposits)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddressOccupationDashboard));
