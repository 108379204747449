/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import Select from 'react-select';
import {
  UncontrolledPopover, PopoverHeader,
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import { endOfDay, startOfDay, subDays } from 'date-fns';
import moment from 'moment';
import DateRangePicker from '../../components/date/DateRangePicker.jsx';
import ROUTES from '../../config/routes';
import CardsStats from './cardsStats';
import {
  getInboundOrdersDashboardInfo,
  setDashboardInboundOrdersDates, setSelectedUserBranches,
} from '../../app/store/actions/inboundOrders';
import InboundOrderHelpers from '../../helpers/inboundOrders';
import DoughnutChart from './doughnutChart';
import LineChart from './lineChart';
import Table from '../../components/table/Table';
import FilterSelectionList from '../../components/form/filterSelectionList';

import { getOrdersTypeOptions } from '../../app/store/actions/typeOrder';
import { getSupplier, getSupplierOptions } from '../../app/store/actions/suppliers';

class InboundOrderDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      defaultSorted: [{
        id: 'orderNumber',
        desc: false,
      }],
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.showInboundOrderDetail(rows.row)}
                >
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE342' /* Fornecedor */),
        accessor: 'supplier.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{InboundOrderHelpers.inboundOrderStatus(row.value)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {InboundOrderHelpers.inboundOrderStatus().map((element, index) => (
              (element.value !== 2 && element.value !== 8) && (
                <option
                  key={index}
                  value={element.value}
                >
                  {element.label}
                </option>
              )
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE444' /* Data de Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L')}
          </span>
        ),
      }, {
        Header: I18n.t('BEE405' /* Volumes */),
        accessor: 'expectedVolumes',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 85,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{parseInt(row.value || 0, 10)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE27' /* Produtos */),
        accessor: 'products.length',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row.value || 0}</span>
          </div>
        ),
      },
      {
        Header: () => (
          <span id="progressHeader">
            {I18n.t('BEE54' /* Progresso */)}
            <UncontrolledPopover trigger="hover" placement="top" target="progressHeader">
              <PopoverHeader style={{ textAlign: 'center' }}>
                {I18n.t('BEE2551' /* Apresenta a % de itens armazenados do documento */)}
              </PopoverHeader>
            </UncontrolledPopover>
          </span>
        ),
        accessor: 'progress',
        minWidth: 100,
        // filterMethod: (filter, row) => String(
        //   inboundOrderProgress(row.status, row._original.products),
        // ).includes(filter.value),
        Cell: (row) => {
          const progress = row.value;

          return (
            <div className="progress rounded-corner" id="progressHeader">
              <div
                className="progress-bar f-s-10 f-w-600"
                style={{
                  width: `${progress}%`,
                  // eslint-disable-next-line no-nested-ternary
                  backgroundColor: ((progress > 70) ? '#00acac' : ((progress > 40) ? '#f59c1a' : '#ff5b57')),
                  transition: 'all .2s ease-out',
                  minWidth: '30px',
                }}
              >
                {progress}
                %
              </div>
            </div>
          );
        },
      },
    ];

    this.breadcrumb = [
      { value: I18n.t('BEE42' /* Dashboard */), iconClassName: 'fa fa-chart-line m-t-10 m-r-5' },
      { value: I18n.t('BEE36' /* Recebimento */) },
      { value: I18n.t('BEE2417' /* Status por Documento */), active: true },
    ];
  }

  async componentDidMount() {
    if (!this.props.location.state || !Object.keys(this.props.location.state).length) {
      const startDate = moment().subtract(30, 'd');
      const endDate = moment();
      const dates = { startDate, endDate };

      await this.props.setDashboardInboundOrdersDates(dates);

      await this.getInboundOrders();

      const listOrderTypes = await this.props.getOrdersTypeOptions();
      const listSuppliers = await this.props.getSupplierOptions();

      this.setState({
        listOrderTypes,
        listSuppliers,
      });
    }

    this.props.history.push({
      state: {},
    });
  }

  showInboundOrderDetail = (inboundOrder) => {
    this.props.history.push({
      pathname: ROUTES.INBOUND_ORDER_DETAIL,
      state: {
        inboundOrder: inboundOrder._original,
        dashboardRoute: 'inboundOrder',
      },
      dice: {
        startDate: moment(this.state.startDate).format('MM/DD/YYYY'),
        endDate: moment(this.state.endDate).format('MM/DD/YYYY'),
        currentRange: this.state.currentRange,
        page: this.state.page,
      },
    });
  };

  handleDateApplyEvent = async (value) => {
    const startDate = value[0];
    const endDate = value[1];

    const dates = { startDate, endDate };
    await this.props.setDashboardInboundOrdersDates(dates);
  };

  handleBranchesSelected = async (event) => {
    const selectedBranches = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const element of event) {
      selectedBranches.push({
        value: element.value,
        label: element.label,
      });
    }

    await this.props.setSelectedUserBranches(selectedBranches);
  };

  handleOrderTypesSelected = async (event) => {
    const selectedOrderTypes = [];

    for (const element of event) {
      selectedOrderTypes.push({
        value: element.value,
        label: element.label,
      });
    }

    this.setState({
      selectedOrderTypes: selectedOrderTypes || {},
    });
  };

  handleSuppliersSelected = async (event) => {
    const selectedSuppliers = [];

    for (const element of event) {
      selectedSuppliers.push({
        value: element.value,
        label: element.label,
      });
    }

    this.setState({
      selectedSuppliers: selectedSuppliers || {},
    });
  };

  getInboundOrders = async () => {
    const {
      startDate, endDate,
    } = this.props.dates;
    const { currentRange } = this.props;

    const branchesToSearch = [];
    if (this.props.selectedBranches) {
      // eslint-disable-next-line no-restricted-syntax
      for (const { value } of this.props.selectedBranches) {
        branchesToSearch.push(value);
      }
    }

    const orderTypesToSearch = [];
    if (this.state.selectedOrderTypes) {
      for (const { value } of this.state.selectedOrderTypes) {
        orderTypesToSearch.push(value);
      }
    }

    const suppliersToSearch = [];
    if (this.state.selectedSuppliers) {
      for (const { value } of this.state.selectedSuppliers) {
        suppliersToSearch.push(value);
      }
    }

    await this.props.getInboundOrdersDashboardInfo(
      startDate,
      endDate,
      true,
      branchesToSearch,
      currentRange,
      orderTypesToSearch,
      suppliersToSearch,
    );

    const existOrder = {};

    this.setState({
      selectedOrder: existOrder || {},
    });
  };

  quantitySet = (data, status) => data.filter((element) => element.status === status).length;

  render() {
    const startDate = startOfDay(subDays(new Date(), 30));
    const endDate = endOfDay(new Date());
    const { inboundOrdersList = [] } = this.props;
    const {
      defaultSorted, selectedOrder, listOrderTypes, listSuppliers,
    } = this.state;

    const cards = [
      {
        title: InboundOrderHelpers.inboundOrderStatus(7).toUpperCase() /* Armazenado */,
        quantity: this.quantitySet(inboundOrdersList, 7),
        isMain: true,
      },
      {
        title: InboundOrderHelpers.inboundOrderStatus(3).toUpperCase() /* Conferência Física Pendente */,
        quantity: this.quantitySet(inboundOrdersList, 3),
        isMain: false,
      },
      {
        title: InboundOrderHelpers.inboundOrderStatus(4).toUpperCase() /* Conferência Física Iniciada */,
        quantity: this.quantitySet(inboundOrdersList, 4),
        isMain: false,
      },
      {
        title: InboundOrderHelpers.inboundOrderStatus(5).toUpperCase() /* Conferência Física Finalizada */,
        quantity: this.quantitySet(inboundOrdersList, 5),
        isMain: false,
      },
      {
        title: InboundOrderHelpers.inboundOrderStatus(1).toUpperCase() /* Pendente de Placa */,
        quantity: this.quantitySet(inboundOrdersList, 1),
        isMain: false,
      },
      {
        title: InboundOrderHelpers.inboundOrderStatus(6).toUpperCase() /* Em Armazenagem */,
        quantity: this.quantitySet(inboundOrdersList, 6),
        isMain: false,
      },
      {
        title: InboundOrderHelpers.inboundOrderStatus(9).toUpperCase() /* Cancelado */,
        quantity: this.quantitySet(inboundOrdersList, 9),
        isMain: false,
      },
    ];

    return (
      <div>
        {!!this.breadcrumb && this.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.breadcrumb.map((it, index) => (
                <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {
                    it.iconClassName
                      ? (
                        <>
                          <i className="fa fa-chart-line m-t-10 m-r-5" />
                          {' '}
                          {it.value}
                        </>
                      ) : (
                        it.value
                      )
                  }
                </li>
              ))}
            </ol>
          </div>
        )}
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE1274' /* Dashboard de Recebimento */)}
          </h1>
        </div>
        <div className="d-sm-flex">
          <div className="d-flex mt-2">
            <DateRangePicker
              onChange={(value) => {
                this.handleDateApplyEvent(value);
              }}
              defaultValue={[startDate, endDate]}
            />
            <UncontrolledPopover trigger="hover" placement="top" target="dateRangePicker">
              <PopoverHeader>
                { I18n.t('BEE3342', { 0: (I18n.t('BEE3344' /* data de criação */)) } /* O intervalo segue o critério pela %{0} */) }
              </PopoverHeader>
            </UncontrolledPopover>
          </div>
          {/* Filiais */}
          <div className="d-flex mt-2">
            <FilterSelectionList
              value={this.props.selectedBranches}
              onChangeValue={this.handleBranchesSelected}
              placeholderValue={I18n.t('BEE2424' /* Todas as filiais estão selecionadas */)}
              optionsValue={this.props.userBranches}
              onClickValue={this.getInboundOrders}
            />
          </div>
          {/* Tipo de Documento */}
          <div className="d-flex mt-2 ml-1">
            <FilterSelectionList
              value={this.props.selectedOrderTypes}
              onChangeValue={this.handleOrderTypesSelected}
              placeholderValue={I18n.t('BEE3355' /* Todos os tipos de documentos estão selecionados. */)}
              optionsValue={listOrderTypes}
              onClickValue={this.getInboundOrders}
            />
          </div>
          {/* Fornecedores */}
          <div className="d-flex mt-2 ml-1">
            <FilterSelectionList
              value={this.props.selectedSuppliers}
              onChangeValue={this.handleSuppliersSelected}
              placeholderValue={I18n.t('BEE3601' /* Todos os fornecedores foram selecionados. */)}
              optionsValue={listSuppliers}
              onClickValue={this.getInboundOrders}
            />
          </div>
        </div>
        <h2 className="page-header mb-0 mt-3">
          {I18n.t('BEE2418' /* Análise de Documentos por Status */)}
        </h2>
        <CardsStats cards={cards} sizeColumns={3} />
        <h2 className="page-header mb-0 mt-4">
          {I18n.t('BEE2419' /* Análise por Linhas */)}
        </h2>
        <div className="row">
          <div className="col-xl-6">
            <LineChart
              typeChart="line"
              lineChartCheckData={this.props.lineChartCheckData}
              title={I18n.t('BEE2421' /* Linhas conferidas por dia */)}
            />
          </div>
          <div className="col-xl-6">
            <DoughnutChart
              doughnutChartData={this.props.doughnutChartData}
              title={I18n.t('BEE2422' /* Quantidade de linhas pendentes por status */)}
            />
          </div>
        </div>
        <div>
          <Table
            downloadCSV
            onClickCollapse
            panelHeaderProps={{
              onClickReload: () => this.getInboundOrders(),
              pageDescription: I18n.t('BEE793' /* Todos */).toUpperCase(),
            }}
            filterable
            data={inboundOrdersList}
            columns={this.tableColumns}
            expander
            defaultPageSize={10}
            defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            noDataText={I18n.t('BEE2423' /* Não há dados na data informada */)}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: selectedOrder && rowInfo.original.id === selectedOrder.id ? 'silver' : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inboundOrdersList: state.inboundOrders && state.inboundOrders.inboundOrdersList,
  currentRange: state.inboundOrders.currentRange,
  userBranches: state.inboundOrders.userBranches,
  selectedBranches: state.inboundOrders.selectedBranches,
  lineChartCheckData: state.inboundOrders && state.inboundOrders.chartsPanel.lineChartCheck,
  doughnutChartData: state.inboundOrders && state.inboundOrders.chartsPanel.lineProductStatus,
  dates: state.inboundOrders.dates,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedUserBranches: (selectedBranches) => dispatch(setSelectedUserBranches(selectedBranches)),
  getInboundOrdersDashboardInfo:
    (
      startDate,
      endDate,
      getCharts,
      selectedBranches,
      currentRange,
      listOrderTypes,
      listSuppliers,
    ) => dispatch(getInboundOrdersDashboardInfo(
      startDate,
      endDate,
      getCharts,
      selectedBranches,
      currentRange,
      listOrderTypes,
      listSuppliers,
    )),
  setDashboardInboundOrdersDates: (dates) => dispatch(setDashboardInboundOrdersDates(dates)),
  getOrdersTypeOptions: () => dispatch(getOrdersTypeOptions()),
  getSupplierOptions: () => dispatch(getSupplierOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InboundOrderDashboard));
