/* eslint-disable class-methods-use-this */
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { I18n } from 'react-redux-i18n';
import { Panel, PanelBody, PanelHeader } from '../panel/panel';
import { I18nGetLocaleCurrency } from '../../helpers/i18n';

class BarChart extends React.PureComponent {
  constructor() {
    super();

    this.state = {

    };
  }

  createAndUpdateCards = () => {
    const { barChartValues } = this.props;

    const generalStatusCards = [];

    if (barChartValues.cards) {
      barChartValues.cards.forEach((card) => {
        generalStatusCards.push({
          title: card.label,
          quantity: card.value,
          backgroundColor: card.backgroundColor ? card.backgroundColor : '#2d353c',
        });
      });
    }

    return (
      generalStatusCards.map((element) => (
        element.quantity
          ? (
            <div
              key={element.title}
              className="row d-flex flex-column rounded align-items-center
          justify-content-center mt-2"
              style={{ height: '5rem', width: '10rem', backgroundColor: `${element.backgroundColor}` }}
            >
              <div className="row align-items-center text-white font-weight-bold">
                {element.title}
              </div>
              <div className="row align-items-center text-white" style={{ fontSize: '1.2rem' }}>
                {element.quantity}
              </div>
            </div>
          ) : ''
      ))
    );
  };

  handleFormatter = (data) => {
    const {
      isVerticalBar,
      useFormatter = true,
      formatToCurrency = false,
      formatToQuantity = false,
      formatToPercent = false,
      decimalNumber = 0,
    } = this.props;

    const localeCurrency = I18nGetLocaleCurrency(navigator.language);

    if (useFormatter) {
      if (formatToCurrency) {
        data.dataLabels = {
          formatter(val, opts) {
            const percentValue = ((val / opts.globals.seriesTotals) * 100).toFixed(2);
            const currencyVal = val.toLocaleString(
              localeCurrency.language,
              {
                style: 'currency',
                currency: localeCurrency.currency,
              },
            );

            return `${currencyVal} (${percentValue}%)`;
          },
          offsetX: +100,
          style: {
            fontSize: '12px',
            colors: ['#000000'], // Definindo a cor do texto como preto
          },
          /* dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 1,
            opacity: 5,
          }, */
        };

        const classificationNameList = data.xaxis.categories;

        data.xaxis = {
          labels: {
            formatter(value) {
              if (value >= 1000 && value < 1000000) {
                return `${value / 1000}m`;
              } if (value >= 1000000) {
                return `${value / 1000000}M`;
              }
              return value;
            },
          },
          tickAmount: 4,
          categories: classificationNameList,
          tickPlacement: 'on',
        };

        data.tooltip = {
          y: {
            formatter(val) {
              const currencyVal = val.toLocaleString(
                localeCurrency.language,
                {
                  style: 'currency',
                  currency: localeCurrency.currency,
                },
              );

              return `${currencyVal}`;
            },
          },
        };
      } else if (formatToPercent) {
        data.dataLabels = {
          formatter(val) {
            return `${val.toFixed(2)}%`;
          },
        };

        data.tooltip = {
          x: {
            formatter(val, opts) {
              const currencyVal = opts.series[opts.seriesIndex].toLocaleString(
                localeCurrency.language,
                {
                  style: 'currency',
                  currency: localeCurrency.currency,
                },
              );

              return `${currencyVal}`;
            },
          },
          y: {
            formatter(val) {
              const currencyVal = val.toLocaleString(
                localeCurrency.language,
                {
                  style: 'currency',
                  currency: localeCurrency.currency,
                },
              );

              return `${currencyVal}`;
            },
          },
        };
      } else if (formatToQuantity) {
        data.dataLabels = {
          formatter(val, opts) {
            const percentValue = ((val / opts.globals.seriesTotals) * 100).toFixed(2);
            return `${val.toLocaleString(localeCurrency.language)} (${percentValue}%)`;
          },
          offsetX: +50,
          style: {
            fontSize: '12px',
          },
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 1,
            opacity: 5,
          },
        };

        const classificationNameList = data.xaxis.categories;

        data.xaxis = {
          labels: {
            formatter(value) {
              if (value >= 1000 && value < 1000000) {
                return `${value / 1000}m`;
              } if (value >= 1000000) {
                return `${value / 1000000}M`;
              }
              return value;
            },
          },
          tickAmount: 4,
          categories: classificationNameList,
          tickPlacement: 'on',
        };

        data.tooltip = {
          y: {
            formatter(val) {
              return `${val.toLocaleString(localeCurrency.language)}`;
            },
          },
        };
      } else {
        data.dataLabels = {
          offsetY: isVerticalBar ? 10 : 0,
          formatter(val, opts) {
            if (isVerticalBar) {
              return [`${I18n.t('BEE1474' /* Qtde */)}: 
                ${opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]}`,
              `(${val.toFixed()}%)`];
            }
            if (decimalNumber) {
              return `${val.toFixed(decimalNumber)} %`.replace('.', ',');
            }
            return `${I18n.t('BEE1474' /* Qtde */)}:
             ${opts.w.config.series[opts.seriesIndex].data} (${val.toFixed()}%)`;
          },
        };
      }
    }
    return data;
  };

  render() {
    const { barChartValues, height, title } = this.props;
    return (
      <Panel>
        <PanelHeader onClickCollapse onClickReload={this.props.onClickReload}>
          {title}
        </PanelHeader>
        <PanelBody>
          {barChartValues
            ? (
              <div className="d-flex flex-column align-items-center" style={{ flex: 'auto' }}>
                {this.createAndUpdateCards()}
              </div>
            ) : ('TOPZERA') }
          <div id="chart" style={{ height: `${this.props.height ? this.props.height : '150'}px` }}>
            {barChartValues
              ? (
                <div>
                  <ReactApexChart
                    options={this.handleFormatter(barChartValues.options)}
                    series={barChartValues.series}
                    type="bar"
                    height={height || 150}
                  />
                </div>
              )
              : (
                <div
                  className="d-flex flex-column w-100"
                  style={{ height: `${this.props.height ? this.props.height : '150'}px` }}
                >
                  <div
                    className="d-flex align-items-center justify-content-center user-select-none"
                    style={{
                      flex: 'auto', color: '#9b8a79', fontSize: '1rem', fontFamily: 'Helvetica',
                    }}
                  >
                    {I18n.t('BEE2423' /* Não há dados no filtro informado */)}
                  </div>
                </div>
              )}
          </div>
        </PanelBody>
      </Panel>
    );
  }
}

export default BarChart;
