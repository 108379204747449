import moment from 'moment';
import RestService from '../services/rest';

async function getOutboundOrdersList(startDate, endDate, getCharts = '') {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(`outboundOrders/list?startDate=${sDate}&endDate=${eDate}&getCharts=${getCharts}`);
}

async function getOutboundOrdersDashboardInfo(
  startDate,
  endDate,
  getCharts = '',
  selectedBranches,
  currentRange,
  selectedOrderTypes,
  selectedCarriers,
  typeDashboard,
) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    'outboundOrders/list/dashboardInfo',
    { startDate: sDate, endDAte: eDate, getCharts },
    undefined,
    {
      params: {
        selectedBranches, selectedOrderTypes, selectedCarriers, typeDashboard,
      },
    },
  );
}

async function getOutboundOrder(outboundOrderId) {
  return RestService.getAuthenticated(`outboundOrders/detail?outboundOrderId=${outboundOrderId}`);
}

async function getOutboundOrderDetails(dockControlId) {
  return RestService.getAuthenticated('outboundOrders/detail/dockControl', { dockControlId });
}

async function getOutboundOrderByDate(filterStartDate, filterEndDate, typeOfSearch = 1, restrictionPicking = false) {
  const params = `?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}`
    + `&typeOfSearch=${typeOfSearch}&modeRestricted=${restrictionPicking}`;
  return RestService.getAuthenticated(`outboundOrders/listingByDate${params}`);
}

async function getBaptismLabelProducts(baptismLabel) {
  return RestService.getAuthenticated(`outboundOrders/getBaptismLabelProducts?baptismLabel=${baptismLabel}`);
}

async function getOutboundOrderListJustDocument(startDate, endDate) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(`outboundOrders/list/justDocument?startDate=${sDate}&endDate=${eDate}`);
}

async function getOutboundOrderProducts(outboundOrderId) {
  return RestService.getAuthenticated(`outboundOrders/list/outboundOrderProducts?outboundOrderId=${outboundOrderId}`);
}

async function updateOutboundRange(outboundRangeId, updateDataOutboundRange) {
  return RestService.putAuthenticated('outboundOrders/updateOutboundRange', {
    outboundRangeId,
    updateDataOutboundRange,
  });
}

async function updateOutboundOrders(outboundOrderId, updateDataOutboundOrder) {
  return RestService.putAuthenticated('outboundOrders/update', { outboundOrderId, updateDataOutboundOrder });
}

async function confirmDivergence(productRangeId, type, quantity) {
  return RestService.postAuthenticated('outboundOrders/confirmDivergence', {
    productRangeId, type, quantity,
  });
}

async function restrictionRelease(checkedOutboundOrderIds) {
  return RestService.putAuthenticated('outboundOrders/restrictionRelease', { checkedOutboundOrderIds });
}

async function pickingRestrictionRelease(checkedOutboundOrderIds) {
  return RestService.putAuthenticated(
    'outboundOrders/restrictionReleasePicking',
    { checkedOutboundOrderIds },
  );
}

async function cancelOutboundOrder(outboundOrderId) {
  return RestService.postAuthenticated('outboundOrders/cancelOrder', {
    outboundOrderId,
  });
}

async function deallocateOutboundOrder(outboundOrderId) {
  return RestService.postAuthenticated('outboundOrders/deallocateOrder', {
    outboundOrderId,
  });
}

async function cancelOutboundProduct(outboundOrderId, outboundProductId) {
  return RestService.postAuthenticated('outboundOrders/cancelProduct', {
    outboundOrderId, outboundProductId,
  });
}

async function deallocateOutboundProduct(outboundOrderId, outboundProductId) {
  return RestService.postAuthenticated('outboundOrders/deallocateProduct', {
    outboundOrderId, outboundProductId,
  });
}

async function changeUserPickRange(outboundRangeId, userPickId, pickingType) {
  return RestService.postAuthenticated('outboundOrders/range/changeUserPick', {
    outboundRangeId, userPickId, pickingType,
  });
}

async function getAverageTimeDashboardInfo(
  startDate,
  endDate,
  selectedBranches,
  orderTypesToSearch,
  carriersToSearch,
) {
  return RestService.getAuthenticated(
    'outboundOrders/list/getAverageTimeDashboardInfo',
    {},
    {},
    {
      params: {
        selectedBranches,
        startDate,
        endDate,
        orderTypesToSearch,
        carriersToSearch,
      },
    },
  );
}

async function getOutboundOrdersDashboardTotal(
  startDate,
  endDate,
  selectedBranches,
  orderTypesToSearch,
  carriersToSearch,
) {
  const sDate = moment(startDate).format();
  const eDate = moment(endDate).format();
  return RestService.getAuthenticated(
    'outboundOrders/list/dashboard/ExpeditionOverview',
    {},
    {},
    {
      params: {
        startDate: sDate,
        endDate: eDate,
        selectedBranches,
        orderTypesToSearch,
        carriersToSearch,
      },
    },
  );
}

async function getPendingConfirmDelivery(orderNumber, filterStartDate, filterEndDate) {
  return RestService.getAuthenticated(
    'outboundOrders/pendingConfirmDelivery',
    { orderNumber, filterStartDate, filterEndDate },
  );
}

async function confirmDelivery(outboundOrderId, userDocument, withdrawnBy, note) {
  return RestService.postAuthenticated('outboundOrders/confirmDelivery', {
    outboundOrderId, userDocument, withdrawnBy, note,
  });
}

export default {
  getOutboundOrdersList,
  getOutboundOrdersDashboardInfo,
  getOutboundOrder,
  getOutboundOrderByDate,
  getBaptismLabelProducts,
  updateOutboundRange,
  confirmDivergence,
  restrictionRelease,
  getOutboundOrderDetails,
  updateOutboundOrders,
  cancelOutboundOrder,
  cancelOutboundProduct,
  changeUserPickRange,
  getOutboundOrderListJustDocument,
  getOutboundOrderProducts,
  getAverageTimeDashboardInfo,
  getOutboundOrdersDashboardTotal,
  pickingRestrictionRelease,
  deallocateOutboundOrder,
  deallocateOutboundProduct,
  getPendingConfirmDelivery,
  confirmDelivery,
};
