import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';
import { i18nReducer } from 'react-redux-i18n';

import i18nsReducer from './reducers/i18n';
import appReducer from './reducers/app';
import loadingReducer from './reducers/loading';
import usersReducer from './reducers/users';
import companiesReducer from './reducers/companies';
import departmentsReducer from './reducers/departments';
import userFunctionsReducer from './reducers/userFunctions';
import workShiftsReducer from './reducers/workShifts';
import branchesReducer from './reducers/branches';
import warehousesReducer from './reducers/warehouses';
import depositsReducer from './reducers/deposits';
import docksReducer from './reducers/docks';
import printersReducer from './reducers/printers';
import collectorsReducer from './reducers/collectors';
import carriersReducer from './reducers/carriers';
import customersReducer from './reducers/customers';
import suppliersReducer from './reducers/suppliers';
import RangesReducer from './reducers/ranges';
import storageAddressesReducer from './reducers/storageAddresses';
import storageAddressSizesReducer from './reducers/storageAddressSizes';
import storageAddressTypesReducer from './reducers/storageAddressTypes';
import AccessProfileReducer from './reducers/accessProfile';
import productsReducer from './reducers/products';
import materialsFamilyReducer from './reducers/materialsFamily';
import customerProductsReducer from './reducers/customerProducts';
import productBranchesReducer from './reducers/productBranches';
import eansReducer from './reducers/eans';
import packagesReducer from './reducers/packages';
import unitsMeasureReducer from './reducers/unitsMeasure';
import inboundOrdersReducer from './reducers/inboundOrders';
import inventoryErpReducer from './reducers/inventoryErp';
import inboundCheckReducer from './reducers/inboundCheck';
import stocksReducer from './reducers/stocks';
import inboundStorageReducer from './reducers/inboundStorage';
import PrioritiesReducer from './reducers/priorities';
import SchedulesReducer from './reducers/schedules';
import IndirectTasksReducer from './reducers/indirectTasks';
import TransportEquipmentReducer from './reducers/transportEquipment';
import CableCutPlanReducer from './reducers/cableCutPlan';
import CutsMonitoReducer from './reducers/cutsMonito';
import addressTransferWaveReducer from './reducers/addressTransferWave';
import outboundOrdersReducer from './reducers/outboundOrders';
import outboundOrderVolumesReducer from './reducers/outboundOrderVolumes';
import outboundWavesReducer from './reducers/outboundWaves';
import customerBranchReducer from './reducers/customerBranch';
import jobsReducer from './reducers/jobs';
import averageCostReducer from './reducers/averageCost';
import curvesReducer from './reducers/curves';
import bulkImportReducer from './reducers/bulkImport';
import notificationsReducer from './reducers/notifications';
import capacitiesReducer from './reducers/capacities';
import pickingAreaReducer from './reducers/pickingAreas';
import orderTypesReducer from './reducers/typesOrder';
import wizardReducer from './reducers/wizard';
import outboundOrdersIndustryReducer from './reducers/outboundOrdersIndustry';
import agentReducer from './reducers/agent';
import outboundOrderVolumes from './reducers/outboundOrderVolumes';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      i18n: i18nReducer,
      i18nGrid: i18nsReducer,
      app: appReducer,
      loading: loadingReducer,
      users: usersReducer,
      departments: departmentsReducer,
      userFunctions: userFunctionsReducer,
      workShifts: workShiftsReducer,
      companies: companiesReducer,
      branches: branchesReducer,
      warehouses: warehousesReducer,
      deposits: depositsReducer,
      docks: docksReducer,
      printers: printersReducer,
      collectors: collectorsReducer,
      carriers: carriersReducer,
      customers: customersReducer,
      suppliers: suppliersReducer,
      ranges: RangesReducer,
      storageAddresses: storageAddressesReducer,
      storageAddressSizes: storageAddressSizesReducer,
      storageAddressTypes: storageAddressTypesReducer,
      accessProfile: AccessProfileReducer,
      products: productsReducer,
      materialsFamily: materialsFamilyReducer,
      customerProducts: customerProductsReducer,
      productBranches: productBranchesReducer,
      packages: packagesReducer,
      inventoriesErp: inventoryErpReducer,
      eans: eansReducer,
      unitsMeasure: unitsMeasureReducer,
      inboundOrders: inboundOrdersReducer,
      inboundCheck: inboundCheckReducer,
      stocks: stocksReducer,
      inboundStorage: inboundStorageReducer,
      priorities: PrioritiesReducer,
      schedules: SchedulesReducer,
      indirectTasks: IndirectTasksReducer,
      transportEquipment: TransportEquipmentReducer,
      cableCutPlan: CableCutPlanReducer,
      addressTransferWave: addressTransferWaveReducer,
      outboundOrders: outboundOrdersReducer,
      outboundOrderVolumes: outboundOrderVolumesReducer,
      cutsMonito: CutsMonitoReducer,
      outboundWaves: outboundWavesReducer,
      customerBranches: customerBranchReducer,
      jobs: jobsReducer,
      averageCost: averageCostReducer,
      curves: curvesReducer,
      bulkImport: bulkImportReducer,
      notifications: notificationsReducer,
      capacities: capacitiesReducer,
      pickingArea: pickingAreaReducer,
      ordersTypes: orderTypesReducer,
      wizard: wizardReducer,
      outboundOrdersIndustry: outboundOrdersIndustryReducer,
      agent: agentReducer,
    }),
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};
